// import React from 'react';
// import { Link } from 'react-router-dom';
// // import { category } from '../../data/products';
// import { category } from '../../data/products2';

// function Category(props) {
//     const { cate, setCate, lang } = props;
//     const activeClassName = "active-item";
//     const cateClass0 = cate === '0' ? activeClassName : "";
//     const cateClass1 = cate === '1' ? activeClassName : "";
//     const cateClass2 = cate === '2' ? activeClassName : "";
//     const cateClass3 = cate === '3' ? activeClassName : "";
//     const cateClass4 = cate === '4' ? activeClassName : "";
//     const cateClass5 = cate === '5' ? activeClassName : "";
//     const cateClass6 = cate === '6' ? activeClassName : "";

//     return (
//     <>
//     {/* <!-- Product Category --> */}
//     <nav className="background-white background-primary-hightlight">
//         <div className="line">
//             <div className="top-nav s-12 l-10">
//                 <ul className="right chevron">
//                     <li className={cateClass0} onClick={()=>setCate('0')}><Link to="#">{category['category0'].title[lang]}</Link></li>
//                     <li className={cateClass1} onClick={()=>setCate('1')}><Link to="#">{category['category1'].title[lang]}</Link></li>
//                     <li className={cateClass2} onClick={()=>setCate('2')}><Link to="#">{category['category2'].title[lang]}</Link></li>
//                     <li className={cateClass3} onClick={()=>setCate('3')}><Link to="#">{category['category3'].title[lang]}</Link></li>
//                     <li className={cateClass4} onClick={()=>setCate('4')}><Link to="#">{category['category4'].title[lang]}</Link></li>
//                     <li className={cateClass5} onClick={()=>setCate('5')}><Link to="#">{category['category5'].title[lang]}</Link></li>
//                     <li className={cateClass6} onClick={()=>setCate('6')}><Link to="#">{category['category6'].title[lang]}</Link></li>
//                 </ul>
//             </div>
//         </div>
//     </nav>
//     </>
//     );
// }
    
//  export default Category;   
    
    
import React from 'react';
import { Link } from 'react-router-dom';
// import { category } from '../../data/products';
import { category } from '../../data/products2';

function Category(props) {
    const { cate, lang } = props;
    const activeClassName = "active-item";
    const cateClass0 = cate === '0' ? activeClassName : "";
    const cateClass1 = cate === '1' ? activeClassName : "";
    const cateClass2 = cate === '2' ? activeClassName : "";
    const cateClass3 = cate === '3' ? activeClassName : "";
    const cateClass4 = cate === '4' ? activeClassName : "";
    const cateClass5 = cate === '5' ? activeClassName : "";
    const cateClass6 = cate === '6' ? activeClassName : "";

    return (
    <>
    {/* <!-- Product Category --> */}
    <nav className="background-white background-primary-hightlight">
        <div className="line">
            <div className="top-nav s-12 l-10">
                <ul className="right chevron">
                    <li className={cateClass0} ><Link to="/products">{category['category0'].title[lang]}</Link></li>
                    <li className={cateClass1} ><Link to="/products/1">{category['category1'].title[lang]}</Link></li>
                    <li className={cateClass2} ><Link to="/products/2">{category['category2'].title[lang]}</Link></li>
                    <li className={cateClass3} ><Link to="/products/3">{category['category3'].title[lang]}</Link></li>
                    <li className={cateClass4} ><Link to="/products/4">{category['category4'].title[lang]}</Link></li>
                    <li className={cateClass5} ><Link to="/products/5">{category['category5'].title[lang]}</Link></li>
                    <li className={cateClass6} ><Link to="/products/6">{category['category6'].title[lang]}</Link></li>
                </ul>
            </div>
        </div>
    </nav>
    </>
    );
}
    
 export default Category;   
    
    