export const contact = {
    title: {ko: "연락", en:"Contact US"},
};

export const company = {
    title: {ko: "회사정보", en:"COMPANY INFORMATION"},
    addressTitle: {ko: "회사주소", en:"Company Address"},       
    address1: {ko: "경기도 광주시", en:"421-2 Sinwol-ri,"},       
    address2: {ko: "초월읍 신월리", en:"Chowol-myeon, Gwangju-si,"},       
    address3: {ko: "421-2 [464-864 ]", en:"Gyeonggi-do, South Korea"},       
    emailTitle: {ko: "이메일", en:"E-mail"},       
    email1: {ko: "bioagro@hanmail.net", en:"bioagro@hanmail.net"},       
    // email2: {ko: "bioagro@hanmail.net", en:"bioagro@hanmail.net"},       
    // email3: {ko: "bioagro@hanmail.net", en:"bioagro@hanmail.net"},       
    phoneTitle: {ko: "전화", en:"Phone Numbers"},
    phone1: {ko: "전화: 031-798-1231", en:"Phone: 031-798-1231"},
    phone2: {ko: "팩스: 031-798-2296", en:"Fax: 031-798-2296"},
    // phone3: {ko: "031-798-1231", en:"031-798-1231"},
};

export const contactForm= {
    title: {ko: "문의는 전화나 이메일로 해주세요", en:"Use Phone or Email for Question"},
    emailPlaceHolder: {ko: "이메일", en:"Your e-mail"},
    namePlaceHolder: {ko: "이름", en:"Your name"},
    subjectPlaceHolder: {ko: "주제", en:"Subject"},
    messagePlaceHolder: {ko: "내용", en:"Your message"},
    buttonTitle: {ko: "보내기(준비중)", en:"Submit ( construction )"},
};

export const map = {
    buttonTile: {ko: "큰 지도", en:"View larger map"},
    guide: {ko: "", en:""},
    api:"AIzaSyBs-1gGzLhn_7QS9AvhLUu-2oPZHnavtEM",
    url: "https://www.google.com/maps/embed/v1/place?q=%EA%B2%BD%EA%B8%B0%EB%8F%84%20%EA%B4%91%EC%A3%BC%EC%8B%9C%20%EC%B4%88%EC%9B%94%EC%9D%8D%20%EC%8B%A0%EC%9B%94%EB%A6%AC%20421-2%20&key=AIzaSyARhMM3e54DOfoAoPPk5vAZ5XChro2UDfU"
}