// import React, { useState } from 'react';
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
// } from 'react-router-dom';
// import './App.css';
// import Header from './components/Header';
// import Footer from './components/Footer';
// import Home from './routes/home';
// import Products from './routes/products';
// import Product from './routes/product';
// import About from './routes/about';
// import Resources from './routes/resources';
// import Resource from './routes/resource';
// import Contact from './routes/contact';
// import { useCookies } from 'react-cookie';

// function App() {
//   const [cookies, setCookie] = useCookies(['lang']);

//   // ko, en
//   const [lang, setLang] = useState(cookies.lang || 'ko'); 
//   const handleLang = (lang) => {
//     setCookie('lang', lang);
//     setLang(lang);
//   }

//   return (
//     <Router>
//       <div className="App">
//         <Header lang={lang} setLang={handleLang}/>
//         <Switch>
//           <Route path="/products">
//             <Products lang={lang}/>
//           </Route>
//           <Route path="/product/:key">
//             <Product lang={lang}/>
//           </Route>
//           <Route path="/about">
//             <About lang={lang}/>
//           </Route>
//           <Route path="/resources">
//             <Resources lang={lang}/>
//           </Route>
//           <Route path="/resource/:key">
//             <Resource lang={lang}/>
//           </Route>
//           <Route path="/contact">
//             <Contact lang={lang}/>
//           </Route>
//           <Route path="/">
//             <Home lang={lang}/>
//           </Route>
//         </Switch>
//         <Footer lang={lang}/>
//       </div>
//     </Router>
//   );
// }

// export default App;
import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './routes/home';
import Products from './routes/products';
import Product from './routes/product';
import About from './routes/about';
import Resources from './routes/resources';
import Resource from './routes/resource';
import Contact from './routes/contact';
// import Item from './routes/item';
import { useCookies } from 'react-cookie';

function App() {
  const [cookies, setCookie] = useCookies(['lang']);

  // ko, en
  const [lang, setLang] = useState(cookies.lang || 'ko'); 
  const handleLang = (lang) => {
    setCookie('lang', lang);
    setLang(lang);
  }

  return (
    <Router>
      <div className="App">
        <Header lang={lang} setLang={handleLang}/>
        <Switch>
          <Route path="/products/:cate/:itemKey">
            <Product lang={lang}/>
          </Route>
          <Route path="/products/:cate">
            <Products lang={lang}/>
          </Route>
          <Route path="/products">
            <Products lang={lang}/>
          </Route>
          <Route path="/about">
            <About lang={lang}/>
          </Route>
          <Route path="/resources/:itemKey">
            <Resource lang={lang}/>
          </Route>
          <Route path="/resources">
            <Resources lang={lang}/>
          </Route>
          <Route path="/contact">
            <Contact lang={lang}/>
          </Route>
          <Route path="/" exact>
            <Home lang={lang}/>
          </Route>
        </Switch>
        <Footer lang={lang}/>
      </div>
    </Router>
  );
}

export default App;