import React from 'react';
import { contact, company, contactForm, map } from '../../data/contact';

function Contact(props){
  const { lang } = props;
    return(
        <>
      {/* <!-- MAIN --> */}
    <main role="main">
      {/* <!-- Content --> */}
      <article>
        <header className="section background-primary text-center">
          <h1 className="text-white margin-bottom-0 text-size-50 text-thin text-line-height-1">{contact.title[lang]}</h1>
        </header>
        <div className="section background-white"> 
          <div className="line">
            <div className="margin">
              
              {/* <!-- Company Information --> */}
              <div className="s-12 m-12 l-6">
                <h2 className="text-uppercase text-strong margin-bottom-30">{company.title[lang]}</h2>
                <div className="float-left">
                  <i className="icon-placepin background-primary icon-circle-small text-size-20"></i>
                </div>
                <div className="margin-left-80 margin-bottom">
                  <h4 className="text-strong margin-bottom-0">{company.addressTitle[lang]}</h4>
                  <p>
                    {company.address1 && company.address1[lang]}<br/>
                    {company.address2 && company.address2[lang]}<br/>
                    {company.address3 && company.address3[lang]}<br/>
                  </p>               
                </div>
                <div className="float-left">
                  <i className="icon-paperplane_ico background-primary icon-circle-small text-size-20"></i>
                </div>
                <div className="margin-left-80 margin-bottom">
                  <h4 className="text-strong margin-bottom-0">{company.emailTitle[lang]}</h4>
                  <p>
                    {company.email1 && company.email1[lang]}<br/>
                    {company.email2 && company.email2[lang]}<br/>
                    {company.email3 && company.email3[lang]}<br/>
                  </p>              
                </div>
                <div className="float-left">
                  <i className="icon-smartphone background-primary icon-circle-small text-size-20"></i>
                </div>
                <div className="margin-left-80">
                  <h4 className="text-strong margin-bottom-0">{company.phoneTitle[lang]}</h4>
                  <p>
                    {company.phone1 && company.phone1[lang]}<br/>
                    {company.phone2 && company.phone2[lang]}<br/>
                    {company.phone3 && company.phone3[lang]}<br/>
                  </p>             
                </div>
              </div>
              
              {/* <!-- Contact Form --> */}
              <div className="s-12 m-12 l-6">
                <h2 className="text-uppercase text-strong margin-bottom-30">{contactForm.title[lang]}</h2>
                <form className="customform">
                  <div className="line">
                    <div className="margin">
                      <div className="s-12 m-12 l-6">
                        <input name="email" className="required email border-radius" placeholder={contactForm.emailPlaceHolder[lang]} title="Your e-mail" type="text" />
                      </div>
                      <div className="s-12 m-12 l-6">
                        <input name="name" className="name border-radius" placeholder={contactForm.namePlaceHolder[lang]} title="Your name" type="text" />
                      </div>
                    </div>
                  </div>
                  <div className="s-12"> 
                    <input name="subject" className="subject border-radius" placeholder={contactForm.subjectPlaceHolder[lang]} title="Subject" type="text" />
                  </div>
                  <div className="s-12">
                    <textarea name="message" className="required message border-radius" placeholder={contactForm.messagePlaceHolder[lang]} rows="3"></textarea>
                  </div>
                  <div className="s-12 m-12 l-4"><button className="submit-form button background-primary border-radius text-white" type="submit">{contactForm.buttonTitle[lang]}</button></div> 
                </form>
              </div>  
            </div>  
          </div> 
        </div> 
      </article>
      {/* <div className="background-primary padding text-center">
        <a href="/"><i className="icon-facebook_circle icon2x text-white"></i></a> 
        <a href="/"><i className="icon-twitter_circle icon2x text-white"></i></a>
        <a href="/"><i className="icon-google_plus_circle icon2x text-white"></i></a>
        <a href="/"><i className="icon-instagram_circle icon2x text-white"></i></a>                                                                        
      </div> */}
      {/* <!-- MAP --> */}
      <div className="s-12 grayscale center">  	  
        {/* <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1459734.5702753505!2d16.91089086619977!3d48.577103681657675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ssk!2ssk!4v1457640551761" width="100%" height="450" frameBorder="0" style={{"border":"0"}}></iframe> */}
        <iframe title="map" src={map.url} width="100%" height="450" frameBorder="0" style={{"border":"0"}}></iframe>
      </div>
    </main>
    </>

    );
}

export default Contact;