import product1_1a from './images-products/1-1a-parapa.jpg';
import product1_2a from './images-products/1-2a-nimoil.jpg';
import product1_3a from './images-products/1-3a-parapa500cc.jpg';
import product1_4a from './images-products/1-4a-pangidduk.jpg';
import product1_5a from './images-products/1-5a-dduk.jpg';
import product1_6a from './images-products/1-6a-cheongdduk.jpg';

import product2_1a from './images-products/2-1a-graolta.jpg';
import product2_2a from './images-products/2-2a-pangikill.jpg';
import product2_3a from './images-products/2-3a-garupowder.jpg';
import product2_4a from './images-products/2-4a-garuliquid.jpg';


import product3_1a from './images-products/3-1a-kanimate20kg.jpg';
import product3_2a from './images-products/3-2a-kitomate5l.jpg';
import product3_3a from './images-products/3-3a-kitomate500cc.jpg';
import product3_4a from './images-products/3-4a-kanimate10kg.jpg';
import product3_5a from './images-products/3-5a-hangeorm.jpg';
import product3_6a from './images-products/3-6a-kwangwhal.jpg';
import product3_7a from './images-products/3-7a-inwhal.jpg';
import product3_8a from './images-products/3-8a-fulvic.jpg';
import product3_9a from './images-products/3-9a-moktan.jpg';
import product3_10a from './images-products/3-10a-arirang.jpg';

import product4_1a from './images-products/4-1a-dangmil.jpg';
import product4_2a from './images-products/4-2a-mokcho.jpg';
import product4_3a from './images-products/4-3a-golbun.jpg';
import product4_4a from './images-products/4-4a-eobun.jpg';
import product4_5a from './images-products/4-5a-daege.jpg';
import product4_6a from './images-products/4-6a-dangmil10l.jpg';

import product5_1a from './images-products/5-1a-algae10l.jpg';
import product5_2a from './images-products/5-2a-calcium.jpg';
import product5_3a from './images-products/5-3a-aekbi.jpg';
import product5_4a from './images-products/5-4a-algae500cc.jpg';
import product5_5a from './images-products/5-5a-amikaku.jpg';
import product5_6a from './images-products/5-6a-gyunche.jpg';

import product6_1a from './images-products/6-1a-arirang20kg.jpg';
import product6_2a from './images-products/6-2a-heukhwal.jpg';
import product6_3a from './images-products/6-3a-bagu.jpg';
import product6_4a from './images-products/6-4a-hok-i.jpg';
import product6_5a from './images-products/6-5a-myeolgu.jpg';
import product6_6a from './images-products/6-6a-domunssak.jpg';
import product6_7a from './images-products/6-7a-gwanghwal2.jpg';
import product6_8a from './images-products/6-8a-inhwal2.jpg';
import product6_9a from './images-products/6-9a-nimssias.jpg';

import head0 from './images-products/head0.jpg';
import head1 from './images-products/head1.jpg';
import head2 from './images-products/head2.jpg';
import head3 from './images-products/head3.jpg';
import head4 from './images-products/head4.jpg';
import head5 from './images-products/head5.jpg';
import head6 from './images-products/head6.jpg';
//--------------------------------------------------

import product1_1 from './images-products/1-1-parapa.jpg';
import product1_2 from './images-products/1-2-nimoil.jpg';
import product1_3 from './images-products/1-3-parapa500cc.jpg';
import product1_4 from './images-products/1-4-pangidduk.jpg';
import product1_5 from './images-products/1-5-dduk.jpg';
import product1_6 from './images-products/1-6-cheongdduk.jpg';

import product2_1 from './images-products/2-1-graolta.jpg';
import product2_2 from './images-products/2-2-pangikill.jpg';
import product2_3 from './images-products/2-3-garupowder.jpg';
import product2_4 from './images-products/2-4-garuliquid.jpg';


import product3_1 from './images-products/3-1-kanimate20kg.jpg';
import product3_2 from './images-products/3-2-kitomate5l.jpg';
import product3_3 from './images-products/3-3-kitomate500cc.jpg';
import product3_4 from './images-products/3-4-kanimate10kg.jpg';
import product3_5 from './images-products/3-5-hangeorm.jpg';
import product3_6 from './images-products/3-6-kwangwhal.jpg';
import product3_7 from './images-products/3-7-inwhal.jpg';
import product3_8 from './images-products/3-8-fulvic.jpg';
import product3_9 from './images-products/3-9-moktan.jpg';
import product3_10 from './images-products/3-10-arirang.jpg';

import product4_1 from './images-products/4-1-dangmil.jpg';
import product4_2 from './images-products/4-2-mokcho.jpg';
import product4_3 from './images-products/4-3-golbun.jpg';
import product4_4 from './images-products/4-4-eobun.jpg';
import product4_5 from './images-products/4-5-daege.jpg';
import product4_6 from './images-products/4-6-dangmil10l.jpg';

import product5_1 from './images-products/5-1-algae10l.jpg';
import product5_2 from './images-products/5-2-calcium.jpg';
import product5_3 from './images-products/5-3-aekbi.jpg';
import product5_4 from './images-products/5-4-algae500cc.jpg';
import product5_5 from './images-products/5-5-amikaku.jpg';
import product5_6 from './images-products/5-6-gyunche.jpg';

import product6_1 from './images-products/6-1-arirang20kg.jpg';
import product6_2 from './images-products/6-2-heukhwal.jpg';
import product6_3 from './images-products/6-3-bagu.jpg';
import product6_4 from './images-products/6-4-hok-i.jpg';
import product6_5 from './images-products/6-5-myeolgu.jpg';
import product6_6 from './images-products/6-6-domunssak.jpg';
import product6_7 from './images-products/6-7-gwanghwal2.jpg';
import product6_8 from './images-products/6-8-inhwal2.jpg';
import product6_9 from './images-products/6-9-nimssias.jpg';

export const products2 = {
    title: {ko: "상품 설명", en: "Product Detail"},
    cateLabel: {ko: "분류: ", en: "Category: "},
    targetLabel: {ko: "대상: ", en: "Target: "},
    featureLabel: {ko: "특징: ", en: "Feature: "},
    effectLabel: {ko: "작용: ", en: "Effect: "},
    amountLabel: {ko: "포장: ", en: "Amount: "},
    priceLabel: {ko: "가격: ", en: "Price: "},
    items:{
        product1_1: {
            category: '1',
            categoryName: {ko: "천연식물 살충제", en: "pesticide"},
            target: {ko: "살균제의 효과증진제", en: "Effect enhancer of pesticide"},
            feature: {ko: "천연 광물성 오일로 작물 및 해충에 코팅", en: "Coating on crops and pests with natural mineral oil"},
            effect: {ko: "식물 표피인 왁스(Wax)층 강화(1,000배,25말)", en: "Reinforced wax layer, which is a plant skin (1,000 times, 25 mal)"},
            amount: {ko: "10Liter", en: "10Liter"},
            thumb: product1_1a,
            image: product1_1,
            title: {ko: "파라파 (10L)", en: "Parapa (10L)"},
            description: {ko: "천연 광물성 오일로 작물 및 해충에 코팅", en: "Coating on crops and pests with natural mineral oil"},
            price: {ko: "150,000원", en: "150,000 won"},
            readMore: "/products/1/product1_1",
        },
        product1_2: {
            category: '1',
            categoryName: {ko: "천연식물 살충제", en: "pesticide"},
            target: {ko: "깍지벌레,노린재류", en: "Worms, stink bugs"},
            feature: {ko: "인도의 천연 NEEM나무 열매의 기름을 유화", en: "Emulsifying oil from natural NEEM tree fruits in India"},
            effect: {ko: "섭식저해,탈피저해,알부화 방지로 충 예방적 자재 500배,예방시:1,000배", en: "Prevention of feeding, anti-epilation, and prevention of albuminization 500 times of preventive materials, prevention: 1,000 times"},
            amount: {ko: "500ml", en: "500ml"},
            thumb: product1_2a,
            image: product1_2,
            title: {ko: "님씨앗오일", en: "Neem oil"},
            description: {ko: "인도의 천연 NEEM나무 열매의 기름을 유화", en: "Emulsifying oil from natural NEEM tree fruits in India"},
            price: {ko: "20,000원", en: "20,000 won"},
            readMore: "/products/1/product1_2",
        },
        product1_3: {
            category: '1',
            categoryName: {ko: "천연식물 살충제", en: "pesticide"},
            target: {ko: "살균제의 효과증진제", en: "Effect enhancer of pesticide"},
            feature: {ko: "천연 광물성 오일로 작물 및 해충에 코팅", en: "Coating on crops and pests with natural mineral oil"},
            effect: {ko: "식물 표피인 왁스(Wax)층 강화(1,000배,25말)", en: "Reinforced wax layer, which is a plant skin (1,000 times, 25 mal)"},
            amount: {ko: "500ml", en: "500ml"},
            thumb: product1_3a,
            image: product1_3,
            title: {ko: "파라파 (500ml)", en: "Parapa (500ml)"},
            description: {ko: "천연 광물성 오일로 작물 및 해충에 코팅", en: "Coating on crops and pests with natural mineral oil"},
            price: {ko: "10,000원", en: "10,000 won"},
            readMore: "/products/1/product1_3",
        },
        product1_4: {
            category: '1',
            categoryName: {ko: "천연식물 살충제", en: "pesticide"},
            target: {ko: "달팽이,거머리,거세미", en: "Snail, leech, spider"},
            feature: {ko: "사포닌이 주원료인 식물 복합 추출물", en: "Plant complex extract with saponin as the main ingredient"},
            effect: {ko: "접촉독에 의한 살충(500배,12.5말)", en: "Insecticidal by contact poison (500 times, 12.5 mal)"},
            amount: {ko: "500ml ,250ml", en: "500ml ,250ml"},
            thumb: product1_4a,
            image: product1_4,
            title: {ko: "팽이뚝", en: "Pangidduk"},
            description: {ko: "사포닌이 주원료인 식물 복합 추출물", en: "Plant complex extract with saponin as the main ingredient"},
            price: {ko: "20,000원", en: "20,000 won"},
            readMore: "/products/1/product1_4",
        },
        product1_5: {
            category: '1',
            categoryName: {ko: "천연식물 살충제", en: "pesticide"},
            target: {ko: "진딧물,총채벌레,온실가루이", en: "Aphids, thrips, greenhouse powder"},
            feature: {ko: "제충국이 주원료인 식물 복합추출물", en: "Complex extract of plants whose main material is pesticide"},
            effect: {ko: "신경마비에 의한 살충1,000배(10말)희석, 파라파 혼용시 효과 상승", en: "1,000 times (10 horses) dilution caused by nerve paralysis, increased effectiveness when parapa mixed"},
            amount: {ko: "200ml", en: "200ml"},
            thumb: product1_5a,
            image: product1_5,
            title: {ko: "뚝", en: "Dduk"},
            description: {ko: "제충국이 주원료인 식물 복합추출물", en: "Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla"},
            price: {ko: "20,000원", en: "20,000 won"},
            readMore: "/products/1/product1_5",
        },
        product1_6: {
            category: '1',
            categoryName: {ko: "천연식물 살충제", en: "pesticide"},
            target: {ko: "응애,배추좀나방애벌레,흑명나방,이화병나방", en: "Mite, cabbage moth, caterpillar, black mite"},
            feature: {ko: "로데논이 주원료인 식물 복합 추출물", en: "Plant-derived extract with rodenone as the main ingredient"},
            effect: {ko: "접촉독,섭식독에의한 살충(500배,12.5말)어독성이 있음", en: "Insect poisoning by contact poison and feeding poison (500 times, 12.5 mal)"},
            amount: {ko: "500ml", en: "500ml"},
            thumb: product1_6a,
            image: product1_6,
            title: {ko: "청뚝", en: "Cheongdduk"},
            description: {ko: "로데논이 주원료인 식물 복합 추출물", en: "Plant-derived extract with rodenone as the main ingredient"},
            price: {ko: "20,000원", en: "20,000 won"},
            readMore: "/products/1/product1_6",
        },
        product2_1: {
            category: '2',
            categoryName: {ko: "천연식물 살균제", en: "germicide"},
            target: {ko: "세균성반점병,청고병예방,적성병,흑성병", en: "Bacterial spot disease, prevention of antibacterial disease, aptitude disease, black sexually transmitted disease"},
            feature: {ko: "세균성 소독제를 주원료로 식품첨가물을 원료로 한 친환경 살균제", en: "Eco-friendly disinfectant with food additives as the main raw material of bacterial disinfectant"},
            effect: {ko: "200-300배 희석(30말/4리터)", en: "200-300 times dilution (30 mal / 4 liters)"},
            amount: {ko: "4리터", en: "4L"},
            thumb: product2_1a,
            image: product2_1,
            title: {ko: "그래올타", en: "Graolta"},
            description: {ko: "세균성 소독제를 주원료로 식품첨가물을 원료로 한 친환경 살균제", en: "Eco-friendly disinfectant with food additives as the main raw material of bacterial disinfectant"},
            price: {ko: "35,000원", en: "35,000 won"},
            readMore: "/products/2/product2_1",
        },
        product2_2: {
            category: '2',
            categoryName: {ko: "천연식물 살균제", en: "germicide"},
            target: {ko: "잿빛곰팡이,탄저,입고,도열병", en: "Gray mold, anthrax, wearing, febrile"},
            feature: {ko: "트리코데마 미생물이 주원료인 복합 미생물제재", en: "Trichoderma microbial complex microbial agent"},
            effect: {ko: "500배 희석(12.5말)", en: "500 times dilution (12.5 mal)"},
            amount: {ko: "500g", en: "500g"},
            thumb: product2_2a,
            image: product2_2,
            title: {ko: "팡이킬", en: "Pangikill"},
            description:  {ko: "트리코데마 미생물이 주원료인 복합 미생물제재", en: "Trichoderma microbial complex microbial agent"},
            price: {ko: "20,000원", en: "20,000 won"},
            readMore: "/products/2/product2_2",
        },
        product2_3: {
            category: '2',
            categoryName: {ko: "천연식물 살균제", en: "germicide"},
            target: {ko: "잿빛곰팡이,흰가루병,포자발아 저해", en: "Gray mold, powdery mildew, spore germination inhibition"},
            feature: {ko: "천연 식품첨가물로 열매를 탄탄하게도 합니다", en: "Natural food additives make the fruit firm"},
            effect: {ko: "200배~300배 희석(10말)", en: "200 to 300 times diluted (10 mal)"},
            amount: {ko: "800g", en: "800g"},
            thumb: product2_3a,
            image: product2_3,
            title: {ko: "가루싹 분제", en: "Garu powder"},
            description: {ko: "천연 식품첨가물로 열매를 탄탄하게도 합니다", en: "Natural food additives make the fruit firm"},
            price: {ko: "10,000원", en: "10,000 won"},
            readMore: "/products/2/product2_3",
        },
        product2_4: {
            category: '2',
            categoryName: {ko: "천연식물 살균제", en: "germicide"},
            target: {ko: "흰가루,노균병,잎곰팡이,탄저병", en: "White powder, mildew, leaf fungus, anthrax"},
            feature: {ko: "천연유황 및 식물추출복합물", en: "Natural sulfur and plant extract complex"},
            effect: {ko: "1,000배 희석(20말-25말) * 25도 이상에서는 살포하지 마십시오", en: "1,000 times dilution (20 words-25 words) * Do not spray over 25 degrees"},
            amount: {ko: "500ml", en: "500ml"},
            thumb: product2_4a,
            image: product2_4,
            title: {ko: "가루싹 액제", en: "Garu liquid"},
            description: {ko: "천연유황 및 식물추출복합물", en: "Natural sulfur and plant extract complex"},
            price: {ko: "20,000원", en: "20,000 won"},
            readMore: "/products/2/product2_4",
        },
        product3_1: {
            category: '3',
            categoryName: {ko: "토양 관리자재", en: "Soil manager"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "게껍질을 주원료로 골분,어분,쌀겨등으로 구성. 펠렛 입상", en: "The main ingredient of crab shells is bone meal, fish meal, and rice bran. Pellet figurine"},
            effect: {ko: "비효:2-1-1", en: "ratio:2-1-1"},
            amount: {ko: "10kg ,20kg", en: "10kg ,20kg"},
            thumb: product3_1a,
            image: product3_1,
            title: {ko: "카니메이트(20kg)", en: "kanimate(20kg)"},
            description: {ko: "게껍질을 주원료로 골분,어분,쌀겨등으로 구성. 펠렛 입상", en: "The main ingredient of crab shells is bone meal, fish meal, and rice bran. Pellet figurine"},
            price: {ko: "8,000원", en: "8,000 won"},
            readMore: "/products/3/product3_1",
        },
        product3_2: {
            category: '3',
            categoryName: {ko: "토양 관리자재", en: "Soil manager"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "방선균의 먹이 내병성 향상(입고,무름병에 효과)", en: "Improving food resistance of Actinomycetes (effective for wear and softness)"},
            effect: {ko: "토양 입단화를 통한 뿌리 발육촉진. 500~1,000배 관주", en: "Promotion of root growth through granulation of soil. 500 to 1,000 times"},
            amount: {ko: "5L", en: "5L"},
            thumb: product3_2a,
            image: product3_2,
            title: {ko: "키토메이트 (5L)", en: "Kitomate (5L)"},
            description: {ko: "방선균의 먹이 내병성 향상(입고,무름병에 효과)", en: "Improving food resistance of Actinomycetes (effective for wear and softness)"},
            price: {ko: "40,000원", en: "40,000 won"},
            readMore: "/products/3/product3_2",
        },
        product3_3: {
            category: '3',
            categoryName: {ko: "토양 관리자재", en: "Soil manager"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "방선균의 먹이 내병성 향상(입고,무름병에 효과)", en: "Improving food resistance of Actinomycetes (effective for wear and softness)"},
            effect: {ko: "500~1,000배 관주", en: "500 to 1,000 times"},
            amount: {ko: "500ml", en: "500ml"},
            thumb: product3_3a,
            image: product3_3,
            title: {ko: "키토메이트(500ml)", en: "Kitomate (500ml)"},
            description: {ko: "방선균의 먹이 내병성 향상(입고,무름병에 효과)", en: "Improving food resistance of Actinomycetes (effective for wear and softness)"},
            price: {ko: "10,000원", en: "10,000 won"},
            readMore: "/products/3/product3_3",
        },
        product3_4: {
            category: '3',
            categoryName: {ko: "토양 관리자재", en: "Soil manager"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "게껍질을 주원료로 골분,여분,쌀겨로 구성. 펄렛입상", en: "The main ingredient of crab shells is bone, extra and rice bran. Pearly figurine"},
            effect: {ko: "비효: 2_1_1", en: "ratio: 2_1_1"},
            amount: {ko: "10kg, 20kg", en: "10kg, 20kg"},
            thumb: product3_4a,
            image: product3_4,
            title: {ko: "카니메이트(10kg)", en: "Kanimate (10kg)"},
            description: {ko: "게껍질을 주원료로 골분,여분,쌀겨로 구성. 펄렛입상", en: "The main ingredient of crab shells is bone, extra and rice bran. Pearly figurine"},
            price: {ko: "5,000원", en: "5,000 won"},
            readMore: "/products/3/product3_4",
        },
        product3_5: {
            category: '3',
            categoryName: {ko: "토양 관리자재", en: "Soil manager"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "기비용 완효성 유기액체 비료 20KG/1통. 한번에 유기물+ 화학비료를 해결.", en: "Cost-effective organic liquid fertilizer 20KG / 1 bucket. Solve organic matter + chemical fertilizer at once."},
            effect: {ko: "유기질 비료 사용량 감량1/3~1/2정도. 하우스1통(150평):기비로5통사용", en: "Organic fertilizer consumption reduction 1/3 ~ 1/2. 1 house (150 pyeong): 5 buckets for use"},
            amount: {ko: "20kg", en: "20kg"},
            thumb: product3_5a,
            image: product3_5,
            title: {ko: "한거름634", en: "Hangeorm634"},
            description: {ko: "기비용 완효성 유기액체 비료 20KG/1통. 한번에 유기물+ 화학비료를 해결.", en: "Cost-effective organic liquid fertilizer 20KG / 1 bucket. Solve organic matter + chemical fertilizer at once."},
            price: {ko: "12,000원", en: "12,000 won"},
            readMore: "/products/3/product3_5",
        },
        product3_6: {
            category: '3',
            categoryName: {ko: "토양 관리자재", en: "Soil manager"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "광합성 세균. 질소억제,암모니아가스 제거,화아분화촉진,착색,발아촉진,당도증진", en: "Photosynthetic bacteria. Nitrogen suppression, ammonia gas removal, follicle differentiation promotion, coloring, germination promotion, sugar content enhancement"},
            effect: {ko: "1,000평~2,000평 관주. 500~1,000배 관주", en: "1,000 pyeong to 2,000 pyeong 500 to 1,000 times"},
            amount: {ko: "10L", en: "10L"},
            thumb: product3_6a,
            image: product3_6,
            title: {ko: "광활(고농축광합성세균)", en: "Kawnghwal (highly concentrated photosynthetic bacteria)"},
            description: {ko: "광합성 세균. 질소억제,암모니아가스 제거,화아분화촉진,착색,발아촉진,당도증진", en: "Photosynthetic bacteria. Nitrogen suppression, ammonia gas removal, follicle differentiation promotion, coloring, germination promotion, sugar content enhancement"},
            price: {ko: "40,000원", en: "40,000 won"},
            readMore: "/products/3/product3_6",
        },
        product3_7: {
            category: '3',
            categoryName: {ko: "토양 관리자재", en: "Soil manager"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "유산균. 발아촉진,인산가용화,발효촉진", en: "Lactobacillus. Promotion of germination, solubilization of phosphoric acid, promotion of fermentation"},
            effect: {ko: "500~1,000배 관주. 1,000평~2,000평관주", en: "500-1,000 times the cross-reference. 1,000 pyeong to 2,000 pyeongwanju"},
            amount: {ko: "10L", en: "10L"},
            thumb: product3_7a,
            image: product3_7,
            title: {ko: "인활(고농축유산균)", en: "Inhwal (highly concentrated lactic acid bacteria)"},
            description: {ko: "유산균. 발아촉진,인산가용화,발효촉진", en: "Lactobacillus. Promotion of germination, solubilization of phosphoric acid, promotion of fermentation"},
            price: {ko: "30,000원", en: "30,000 won"},
            readMore: "/products/3/product3_7",
        },
        product3_8: {
            category: '3',
            categoryName: {ko: "토양 관리자재", en: "Soil manager"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "발근,발아 촉진. 양분의 바란스를 맞추어 주는 신비의 효과", en: "Promoting rooting and germination. Mysterious effect to match the balance of nutrients"},
            effect: {ko: "엽면 살포로도 효과 발휘(6.5말)", en: "Effective by spraying foliar (6.5 mal)"},
            amount: {ko: "250ml", en: "250ml"},
            thumb: product3_8a,
            image: product3_8,
            title: {ko: "펄빅산", en: "Fulvic acid"},
            description: {ko: "발근,발아 촉진. 양분의 바란스를 맞추어 주는 신비의 효과", en: "Promoting rooting and germination. Mysterious effect to match the balance of nutrients"},
            price: {ko: "15,000원", en: "15,000 won"},
            readMore: "/products/3/product3_8",
        },
        product3_9: {
            category: '3',
            categoryName: {ko: "토양 관리자재", en: "Soil manager"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "연작지 토양의 토양 개량. 양분 보위력(CEC)최대", en: "Soil improvement of cropland soil. Nutrient Bowel Power (CEC) Max"},
            effect: {ko: "50평에 1포 살포로 염류집적 해소", en: "Eliminate salt accumulation by spraying 1 packet per 50 pyeong"},
            amount: {ko: "10kg", en: "10kg"},
            thumb: product3_9a,
            image: product3_9,
            title: {ko: "부식산", en: "Humic acid"},
            description: {ko: "연작지 토양의 토양 개량. 양분 보위력(CEC)최대", en: "Soil improvement of cropland soil. Nutrient Bowel Power (CEC) Max"},
            price: {ko: "15,000원", en: "15,000 won"},
            readMore: "/products/3/product3_9",
        },
        product3_10: {
            category: '3',
            categoryName: {ko: "토양 관리자재", en: "Soil manager"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "유기재배용 유기퇴비. 펠렛입상", en: "Organic compost for organic cultivation. Pellet figurine"},
            effect: {ko: "게껍질, 어분, 깻묵, 일라이트, 쌀겨, 버섯박, 당밀, 미생물 등으로 발효. 비효: 3.5-1.5-2", en: "Fermented with crab skin, fishmeal, fish cake, illite, rice bran, mushroom foil, molasses, and microorganisms. Invalidity: 3.5-1.5-2"},
            amount: {ko: "20kg", en: "20kg"},
            thumb: product3_10a,
            image: product3_10,
            title: {ko: "맞춤형 퇴비 아리랑골드", en: "Customized compost Arirang Gold"},
            description: {ko: "유기재배용 유기퇴비. 펠렛입상", en: "Organic compost for organic cultivation. Pellet figurine"},
            price: {ko: "8,000원", en: "8,000 won"},
            readMore: "/products/3/product3_10",
        },
        product4_1: {
            category: '4',
            categoryName: {ko: "친환경 액비원료", en: "fertilizer material"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "사탕수수 원당으로 설탕의 원료", en: "Sugar cane raw sugar"},
            effect: {ko: "미생물 발효촉진,양분의 킬게이트화로 흡수율을 높임", en: "Accelerates the fermentation of microorganisms and increases the absorption rate by nutrients and kilgates"},
            amount: {ko: "10리터(14KG), 20리터(28KG)", en: "10L(14KG), 20L(28KG)"},
            thumb: product4_1a,
            image: product4_1,
            title: {ko: "당밀(20리터)", en: "Molasses (20 liters)"},
            description: {ko: "사탕수수 원당으로 설탕의 원료", en: "Sugar cane raw sugar"},
            price: {ko: "20,000원", en: "20,000 won"},
            readMore: "/products/4/product4_1",
        },
        product4_2: {
            category: '4',
            categoryName: {ko: "친환경 액비원료", en: "fertilizer material"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "참나무 연기를 액화시켜 정유하여 제조 토양에서 알카리로 전환된다", en: "Liquefied oak smoke is refined and converted from manufactured soil to alkali"},
            effect: {ko: "감농약,감비료 촉매제로 작용 애상입자율을 1/4로 잘게 부셔준다", en: "It acts as a catalyst for agrochemicals and fertilizers."},
            amount: {ko: "20리터", en: "20L"},
            thumb: product4_2a,
            image: product4_2,
            title: {ko: "목초액", en: "Wood vinegar"},
            description: {ko: "참나무 연기를 액화시켜 정유하여 제조 토양에서 알카리로 전환된다", en: "Liquefied oak smoke is refined and converted from manufactured soil to alkali"},
            price: {ko: "30,000원", en: "30,000 won"},
            readMore: "/products/4/product4_2",
        },
        product4_3: {
            category: '4',
            categoryName: {ko: "친환경 액비원료", en: "fertilizer material"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "액비제조시 주요한 인산과 칼슘의 원료원으로 인산집적이 안되고 흡수율이 높다. 토양살포시 미생물에 의해 분해 서서히흡수되어 낙하산병,배꼽썩음병을 막을수있다.", en: "It is the main source of phosphoric acid and calcium in the production of liquid fertilizer, and has no phosphate accumulation and high absorption. When soil is sprayed, it is slowly decomposed by microorganisms and can be absorbed to prevent parachutes and belly rot."},
            effect: {ko: "천연 인산칼슘으로 뿌리의 발육과 화아분화를 촉진한다. 인산32.5% 칼슘30%", en: "It promotes root growth and flower differentiation with natural calcium phosphate. Phosphoric acid 32.5% Calcium 30%"},
            amount: {ko: "10kg", en: "10kg"},
            thumb: product4_3a,
            image: product4_3,
            title: {ko: "골분", en: "Bone powder"},
            description: {ko: "액비제조시 주요한 인산과 칼슘의 원료원으로 인산집적이 안되고 흡수율이 높다. 토양살포시 미생물에 의해 분해 서서히흡수되어 낙하산병,배꼽썩음병을 막을수있다.", en: "It is the main source of phosphoric acid and calcium in the production of liquid fertilizer, and has no phosphate accumulation and high absorption. When soil is sprayed, it is slowly decomposed by microorganisms and can be absorbed to prevent parachutes and belly rot."},
            price: {ko: "10,000원", en: "10,000 won"},
            readMore: "/products/4/product4_3",
        },
        product4_4: {
            category: '4',
            categoryName: {ko: "친환경 액비원료", en: "fertilizer material"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "생선아미노산으로 생육촉진 및 해충기피효과", en: "Fish amino acid promotes growth and avoids pests"},
            effect: {ko: "성분:비분:질소-인산-가리:8-8-1", en: "Ingredients: Secretion: Nitrogen-Phosphate-Gari: 8-8-1"},
            amount: {ko: "10kg", en: "10kg"},
            thumb: product4_4a,
            image: product4_4,
            title: {ko: "어분", en: "Fish powder"},
            description: {ko: "생선아미노산으로 생육촉진 및 해충기피효과", en: "Fish amino acid promotes growth and avoids pests"},
            price: {ko: "10,000원", en: "10,000 won"},
            readMore: "/products/4/product4_4",
        },
        product4_5: {
            category: '4',
            categoryName: {ko: "친환경 액비원료", en: "fertilizer material"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "토양유용미생물 번식촉진: (특히 방선균)으로 유해미생물억제(뿌리썩음병,무름병등)키틴질은 토양을 떼알 구조화함", en: "Promoting propagation of useful soil microorganisms: (especially actinomycetes), which inhibits harmful microorganisms (root rot, rot, etc.)."},
            effect: {ko: "성분:칼슘함량:20.7% - 질소-인산-가리:4 - 4 - 0..5", en: "Ingredients: Calcium Content: 20.7%-Nitrogen-Phosphate-Gari: 4-4-0..5"},
            amount: {ko: "5kg", en: "5kg"},
            thumb: product4_5a,
            image: product4_5,
            title: {ko: "대게껍질", en: "Large crab shell"},
            description: {ko: "토양유용미생물 번식촉진: (특히 방선균)으로 유해미생물억제(뿌리썩음병,무름병등)키틴질은 토양을 떼알 구조화함", en: "Promoting propagation of useful soil microorganisms: (especially actinomycetes), which inhibits harmful microorganisms (root rot, rot, etc.)."},
            price: {ko: "10,000원", en: "10,000 won"},
            readMore: "/products/4/product4_5",
        },
        product4_6: {
            category: '4',
            categoryName: {ko: "친환경 액비원료", en: "fertilizer material"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "사탕수수 원당으로 설탕의 원료", en: "Sugar cane raw sugar"},
            effect: {ko: "미생물 발효촉진,양분의 킬게이트화로 흡수율 높임", en: "Accelerates the fermentation of microorganisms and increases the absorption rate through nutrients' chelating"},
            amount: {ko: "10리터(14kg), 20리터(28kg)", en: "10L (14kg), 20L (28kg)"},
            thumb: product4_6a,
            image: product4_6,
            title: {ko: "당밀(10리터)", en: "Molasses (10 liters)"},
            description: {ko: "사탕수수 원당으로 설탕의 원료", en: "Sugar cane raw sugar"},
            price: {ko: "10,000원", en: "10,000 won"},
            readMore: "/products/4/product4_6",
        },
        product5_1: {
            category: '5',
            categoryName: {ko: "유기액비", en: "Organic liquid fertilizer"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "천연 해조로 각종 천연 활성물질 함유(베타인,싸이토키닌,안토시아닌,지베렐린등)", en: "Natural seaweed contains various natural active substances (betaine, cytokinin, anthocyanin, gibberellin, etc.)"},
            effect: {ko: "성분:천연황5%,천연가리5% 사용량:관주500평-1,000평/1리터", en: "Ingredients: 5% natural sulfur, 5% natural amount Used: 500 pyeong-1,000 pyeong / 1 liter"},
            amount: {ko: "10L", en: "10L"},
            thumb: product5_1a,
            image: product5_1,
            title: {ko: "알게아(10리터)", en: "Algaea (10 liters)"},
            description: {ko: "천연 해조로 각종 천연 활성물질 함유(베타인,싸이토키닌,안토시아닌,지베렐린등)", en: "Natural seaweed contains various natural active substances (betaine, cytokinin, anthocyanin, gibberellin, etc.)"},
            price: {ko: "70,000원", en: "70,000 won"},
            readMore: "/products/5/product5_1",
        },
        product5_2: {
            category: '5',
            categoryName: {ko: "유기액비", en: "Organic liquid fertilizer"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "식품첨가용 중성 칼슘으로 혼용이 용이하고 흡수력이 좋다", en: "Neutral calcium for food additives, easy mixing and good absorption"},
            effect: {ko: "성분:칼슘함량:40% 사용량:25말/500g 엽면살포", en: "Ingredients: Calcium content: 40% Usage: 25 horses / 500g foliar spray"},
            amount: {ko: "500g", en: "500g"},
            thumb: product5_2a,
            image: product5_2,
            title: {ko: "젖산칼슘", en: "Calcium lactate"},
            description: {ko: "식품첨가용 중성 칼슘으로 혼용이 용이하고 흡수력이 좋다", en: "Neutral calcium for food additives, easy mixing and good absorption"},
            price: {ko: "15,000원", en: "15,000 won"},
            readMore: "/products/5/product5_2",
        },
        product5_3: {
            category: '5',
            categoryName: {ko: "유기액비", en: "Organic liquid fertilizer"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "친환경 유기및 전환기재배에 사용. 바실러스,유산균,효모,광합성세균 함유+광향성 세균+미생물 배양액", en: "Used for eco-friendly organic and conversion equipment cultivation. Bacillus, Lactic Acid Bacteria, Yeast, Photosynthetic Bacteria Included + Photosensitive Bacteria + Microbial Culture"},
            effect: {ko: "토양미생물제재와 유기물의 만남. 10리터로10-200평 관주. 100-200배 엽면살포", en: "Soil microorganisms meet organic matter. 10 liters of 10-200 pyeong in 10 liters. 100-200 times foliar spray"},
            amount: {ko: "10L", en: "10L"},
            thumb: product5_3a,
            image: product5_3,
            title: {ko: "유기액비", en: "Organic liquid fertilizer"},
            description: {ko: "친환경 유기및 전환기재배에 사용. 바실러스,유산균,효모,광합성세균 함유+광향성 세균+미생물 배양액", en: "Used for eco-friendly organic and conversion equipment cultivation. Bacillus, Lactic Acid Bacteria, Yeast, Photosynthetic Bacteria Included + Photosensitive Bacteria + Microbial Culture"},
            price: {ko: "20,000원", en: "20,000 won"},
            readMore: "/products/5/product5_3",
        },
        product5_4: {
            category: '5',
            categoryName: {ko: "유기액비", en: "Organic liquid fertilizer"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "천연 베타인, 싸이토키닌, 안토시아닌 다량함유로 수정보조제.", en: "Water-information preparation with high content of natural betaine, cytokinin, and anthocyanin."},
            effect: {ko: "고농축 해조추출물(해조함량40%)천연황5%,천연가리5% 용량:25말-50말/500ml", en: "Highly concentrated seaweed extract (40% seaweed content) 5% natural sulfur, 5% natural capacity Capacity: 25 horses-50 horses / 500 ml"},
            amount: {ko: "500ml", en: "500ml"},
            thumb: product5_4a,
            image: product5_4,
            title: {ko: "알게아 (500ml)", en: "Algaea (500ml)"},
            description: {ko: "천연 베타인, 싸이토키닌, 안토시아닌 다량함유로 수정보조제.", en: "Water-information preparation with high content of natural betaine, cytokinin, and anthocyanin."},
            price: {ko: "20,000원", en: "20,000 won"},
            readMore: "/products/5/product5_4",
        },
        product5_5: {
            category: '5',
            categoryName: {ko: "유기액비", en: "Organic liquid fertilizer"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "생선아미노산과 핵산을 주원료로 유효미생물과 미량요소를 배합. 효모,EM균,기타 미생물 대사물질", en: "Fish amino acid and nucleic acid are used as main ingredients to mix effective microorganisms and trace elements. Yeast, EM bacteria, and other microbial metabolites"},
            effect: {ko: "생육촉진,비대,당도증대,수세회복. 관주:300-500평/리터", en: "Promotion of growth, hypertrophy, increased sugar content, recovery of rinsing. Irrigation: 300-500 pyeong / liter"},
            amount: {ko: "4L, 10L", en: "4L, 10L"},
            thumb: product5_5a,
            image: product5_5,
            title: {ko: "액상어분", en: "Liquid fish powder"},
            description: {ko: "생선아미노산과 핵산을 주원료로 유효미생물과 미량요소를 배합. 효모,EM균,기타 미생물 대사물질", en: "Fish amino acid and nucleic acid are used as main ingredients to mix effective microorganisms and trace elements. Yeast, EM bacteria, and other microbial metabolites"},
            price: {ko: "30,000원", en: "30,000 won"},
            readMore: "/products/5/product5_5",
        },
        product5_6: {
            category: '5',
            categoryName: {ko: "유기액비", en: "Organic liquid fertilizer"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "친환경 무농약재배에 사용. 효모,유산균,광합성세균,바실러스등 미생물함유", en: "Used for eco-friendly pesticide-free cultivation. Contains microorganisms such as yeast, lactic acid bacteria, photosynthetic bacteria, and Bacillus"},
            effect: {ko: "성분:게껍질,골분,어분,구아노등의 유기물과 관주용비료 및 미량요소 배합. 비효: 3 -2 -4", en: "Ingredients: Contains organic substances such as crab skin, bone meal, fish meal, guano, fertilizer for irrigation and trace elements. ratio: 3 -2 -4"},
            amount: {ko: "10L", en: "10L"},
            thumb: product5_6a,
            image: product5_6,
            title: {ko: "균체액비", en: "Bacterial liquid fertilizer"},
            description: {ko: "친환경 무농약재배에 사용. 효모,유산균,광합성세균,바실러스등 미생물함유", en: "Used for eco-friendly pesticide-free cultivation. Contains microorganisms such as yeast, lactic acid bacteria, photosynthetic bacteria, and Bacillus"},
            price: {ko: "20,000원", en: "20,000 won"},
            readMore: "/products/5/product5_6",
        },
        product6_1: {
            category: '6',
            categoryName: {ko: "친환경 벼재배", en: "rice cultivation"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "유기재배용 유기퇴비. 게껍질, 어분, 깻묵, 일라이트, 쌀겨, 버섯박, 당밀, 미생물 등으로 발효. 펠렛입상", en: "Organic compost for organic cultivation. Fermented with crab skin, fishmeal, fish cake, illite, rice bran, mushroom foil, molasses, and microorganisms. Pellet figurine"},
            effect: {ko: "비효: 3.5-1.5-2", en: "ratio: 3.5-1.5-2"},
            amount: {ko: "20kg", en: "20kg"},
            thumb: product6_1a,
            image: product6_1,
            title: {ko: "맞춤형 퇴비 아리랑골드(20kg)", en: "Customized compost Arirang Gold (20kg)"},
            description: {ko: "유기재배용 유기퇴비. 게껍질, 어분, 깻묵, 일라이트, 쌀겨, 버섯박, 당밀, 미생물 등으로 발효. 펠렛입상", en: "Organic compost for organic cultivation. Fermented with crab skin, fishmeal, fish cake, illite, rice bran, mushroom foil, molasses, and microorganisms. Pellet figurine"},
            price: {ko: "8,000원", en: "8,000 won"},
            readMore: "/products/6/product6_1",
        },
        product6_2: {
            category: '6',
            categoryName: {ko: "친환경 벼재배", en: "rice cultivation"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "독립영양세균(광합성세균, 남조류) 발효미생물(바실러스, 유산균, 효모, 아스퍼질러스) 길항 미생물(방선균, 트리코데마, 슈도모나스). 입제", en: "Independent nutrient bacteria (photosynthetic bacteria, blue-green algae) fermenting microorganisms (Bacillus, lactic acid bacteria, yeast, Aspergillus) antagonistic microorganisms (Actinomycetes, Trichoderma, Pseudomonas). Entry"},
            effect: {ko: "엽류 집적 해소, 미숙 유기물 분해, 가스제거, 양분 가용화", en: "Reduction of leaf accumulation, decomposition of unripe organic matter, degassing, solubilization of nutrients"},
            amount: {ko: "10Kg", en: "10Kg"},
            thumb: product6_2a,
            image: product6_2,
            title: {ko: "흙활", en: "heukhwal"},
            description: {ko: "독립영양세균(광합성세균, 남조류) 발효미생물(바실러스, 유산균, 효모, 아스퍼질러스) 길항 미생물(방선균, 트리코데마, 슈도모나스). 입제", en: "Independent nutrient bacteria (photosynthetic bacteria, blue-green algae) fermenting microorganisms (Bacillus, lactic acid bacteria, yeast, Aspergillus) antagonistic microorganisms (Actinomycetes, Trichoderma, Pseudomonas). Entry"},
            price: {ko: "20,000원", en: "20,000 won"},
            readMore: "/products/6/product6_2",
        },
        product6_3: {
            category: '6',
            categoryName: {ko: "친환경 벼재배", en: "rice cultivation"},
            target: {ko: "물바구미", en: "Water weevil"},
            feature: {ko: "광물성 오일로 수면전개제", en: "Mineral oil for sleep development"},
            effect: {ko: "원액을 200~300평에 20~30군데 부어줌 (가능한 작물에 안닿게 논물 표면에 부움)", en: "Pour the stock solution into 20 ~ 30 places on 200 ~ 300 pyeong (pour it on the surface of the paddy so as not to touch the possible crops)"},
            amount: {ko: "500ml", en: "500ml"},
            thumb: product6_3a,
            image: product6_3,
            title: {ko: "바구뚝", en: "Bagudduk"},
            description: {ko: "광물성 오일로 수면전개제", en: "Mineral oil for sleep development"},
            price: {ko: "12,000원", en: "12,000 won"},
            readMore: "/products/6/product6_3",
        },
        product6_4: {
            category: '6',
            categoryName: {ko: "친환경 벼재배", en: "rice cultivation"},
            target: {ko: "혹명나방, 이화병나방", en: "Common Moth, Ehwabyeong Moth"},
            feature: {ko: "식물추출물과 살충성 미생물제", en: "Plant extracts and pesticidal microorganisms"},
            effect: {ko: "12.5말(500~600평) 살포", en: "Spray 12.5 words (500-600 pyeong)"},
            amount: {ko: "500ml", en: "500ml"},
            thumb: product6_4a,
            image: product6_4,
            title: {ko: "혹이뚝", en: "Hokidduk"},
            description: {ko: "식물추출물과 살충성 미생물제", en: "Plant extracts and pesticidal microorganisms"},
            price: {ko: "20,000원", en: "20,000 won"},
            readMore: "/products/6/product6_4",
        },
        product6_5: {
            category: '6',
            categoryName: {ko: "친환경 벼재배", en: "rice cultivation"},
            target: {ko: "벼멸구, 애멸구, 흰등멸구", en: "Brown planthopper, striatellus, furcifera"},
            feature: {ko: "식물추출물", en: "Plant extracts"},
            effect: {ko: "12.5말(500~600평) 살포", en: "Spray 12.5 words (500-600 pyeong)"},
            amount: {ko: "500ml", en: "500ml"},
            thumb: product6_5a,
            image: product6_5,
            title: {ko: "멸구뚝", en: "Myeolgudduk"},
            description: {ko: "식물추출물", en: "Plant extracts"},
            price: {ko: "20,000원", en: "20,000 won"},
            readMore: "/products/6/product6_5",
        },
        product6_6: {
            category: '6',
            categoryName: {ko: "친환경 벼재배", en: "rice cultivation"},
            target: {ko: "잎도열병, 목도열병, 문고병", en: "Leaf fever, wood fever, Mungo disease"},
            feature: {ko: "길항미생물, 식물추출물", en: "Antagonistic microorganisms, plant extracts"},
            effect: {ko: "25말(1,000평)", en: "25 mal (1,000 pyeong)"},
            amount: {ko: "500ml", en: "500ml"},
            thumb: product6_6a,
            image: product6_6,
            title: {ko: "도문싹", en: "Domunssak"},
            description: {ko: "길항미생물, 식물추출물", en: "Antagonistic microorganisms, plant extracts"},
            price: {ko: "20,000원", en: "20,000 won"},
            readMore: "/products/6/product6_6",
        },
        product6_7: {
            category: '6',
            categoryName: {ko: "친환경 벼재배", en: "rice cultivation"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "고농축 광합성세균, 황화수소 제거, 뿌리발달 촉진, 화아 분화 촉진", en: "Highly concentrated photosynthetic bacteria, removal of hydrogen sulfide, promoting root development, promoting flower differentiation"},
            effect: {ko: "물꼬에 흘려보냄(1,500~2,000평/10 L)", en: "Flowed in water (1,500 ~ 2,000pyeong / 10L)"},
            amount: {ko: "10L", en: "10L"},
            thumb: product6_7a,
            image: product6_7,
            title: {ko: "광활", en: "Kawnghwal"},
            description: {ko: "고농축 광합성세균, 황화수소 제거, 뿌리발달 촉진, 화아 분화 촉진", en: "Highly concentrated photosynthetic bacteria, removal of hydrogen sulfide, promoting root development, promoting flower differentiation"},
            price: {ko: "40,000원", en: "40,000 won"},
            readMore: "/products/6/product6_7",
        },
        product6_8: {
            category: '6',
            categoryName: {ko: "친환경 벼재배", en: "rice cultivation"},
            target: {ko: "토양", en: "soil"},
            feature: {ko: "고농축 유산균, 발아촉진, 종자처리시 100배. 이앙전 잡초 발아 촉진 제거", en: "Highly concentrated lactic acid bacteria, promoting germination, 100 times when seed treatment. Elimination of weed germination"},
            effect: {ko: "200~300배 살포(1,500~2,000평)", en: "200 to 300 times spray (1,500 to 2,000 pyeong)"},
            amount: {ko: "10L", en: "10L"},
            thumb: product6_8a,
            image: product6_8,
            title: {ko: "인활", en: "Inhwal"},
            description: {ko: "고농축 유산균, 발아촉진, 종자처리시 100배. 이앙전 잡초 발아 촉진 제거", en: "Highly concentrated lactic acid bacteria, promoting germination, 100 times when seed treatment. Elimination of weed germination"},
            price: {ko: "30,000원", en: "30,000 won"},
            readMore: "/products/6/product6_8",
        },
        product6_9: {
            category: '6',
            categoryName: {ko: "친환경 벼재배", en: "rice cultivation"},
            target: {ko: "물바구미 및 해충알 부화 방지, 도열병 예방", en: "Prevention of hatching of water weevil and pest eggs, prevention of fever"},
            feature: {ko: "인도의 천연 Neem 나무 씨앗기름을 유화시킨 식물 추출물", en: "Plant extract emulsifying natural Neem tree seed oil from India"},
            effect: {ko: "500배(12.5말) 엽면 살포", en: "500 times (12.5 words) foliar spray"},
            amount: {ko: "500ml", en: "500ml"},
            thumb: product6_9a,
            image: product6_9,
            title: {ko: "님씨앗오일", en: "Neem seed oil"},
            description: {ko: "인도의 천연 Neem 나무 씨앗기름을 유화시킨 식물 추출물", en: "Plant extract emulsifying natural Neem tree seed oil from India"},
            price: {ko: "20,000원", en: "20,000 won"},
            readMore: "/products/6/product6_9",
        },
    },
    readMore: {ko: "더보기", en: "Read more"},
}


export const category = {
    category0: {
        headTitle:  {ko: "모든 상품", en: "Products"},
        title: {ko: "모든 상품", en: "All Products"},
        category: "0",
        image: head0,
    },
    category1: {
        headTitle:  {ko: "", en: "Pesticide"},
        title: {ko: "천연식물 살충제", en: "Pesticide"},
        category: "1",
        image: head1,
    },
    category2: {
        headTitle:  {ko: "", en: "Germicide"},
        title: {ko: "천연식물 살균제", en: "Germicide"},
        category: "2",
        image: head2,
    },
    category3: {
        headTitle:  {ko: "", en: "Soil manager"},
        title: {ko: "토양 관리자재", en: "Soil manager"},
        category: "3",
        image: head3,
    },
    category4: {
        headTitle:  {ko: "", en: "Fertilizer material"},
        title: {ko: "친환경 액비원료", en: "Fertilizer material"},
        category: "4",
        image: head4,
    },
    category5: {
        headTitle:  {ko: "", en: "Organic fertilizer"},
        title: {ko: "유기액비", en: "Organic fertilizer"},
        category: "5",
        image: head5,
    },
    category6: {
        headTitle:  {ko: "", en: "Rice cultivation"},
        title: {ko: "친환경 벼재배", en: "Rice cultivation"},
        category: "6",
        image: head6,
    },
}
