import React from 'react';
import { slides, bestProducts, company, newProducts } from '../../data/home';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';

function Home(props){
  const { lang } = props;
    return(
        <>
    {/* <!-- MAIN --> */}
    <main role="main">
      {/* <!-- Main Carousel --> */}
      <section className="section background-dark">
        <div className="line">
          {/* <div className="carousel-fade-transition owl-carousel carousel-main carousel-nav-white carousel-wide-arrows"> */}
          <OwlCarousel 
          items={1}  
          className="carousel-main" 
          loop
          margin={8}   
          autoplay ={true}
          dots={false}
          animateOut="carousel-fade-transition"
          nav
          >
            {Object.keys(slides).map((key)=>
              <div key={key} className="item">
                <div className="s-12 center">
                  <img src={slides[key].image} alt=""/>
                  <div className="carousel-content">
                    <div className="padding-2x">
                      <div className="s-12 m-12 l-8">
                        <p className="text-white text-s-size-20 text-m-size-40 text-l-size-60 margin-bottom-40 text-thin text-line-height-1">{slides[key].title[lang]}</p>
                        <p className="text-white text-size-16 margin-bottom-30">{slides[key].description[lang]}</p>    
                      </div>                  
                    </div>
                  </div>
                </div>
              </div>
            )}
          </OwlCarousel>
          {/* </div>   */}
        </div>
      </section>
      
      {/* <!-- Section 1 --> */}
      <section className="section background-white"> 
        <div className="line">
          <h2 className="text-thin headline text-center text-s-size-30 margin-bottom-50"><span className="text-primary">{bestProducts.title[lang]}</span></h2>
          <div className="margin best-products">
            {Object.keys(bestProducts.items).map((key)=>
              <div key={key} className="s-12 m-12 l-3 margin-m-bottom best-product">
                <Link className="image-hover-zoom" to={bestProducts.items[key].readMore}><img className="margin-bottom" src={bestProducts.items[key].image} alt=""/></Link>
                <div className="s-12 m-12 l-8 margin-m-bottom">
                  <h2><Link className="text-dark text-primary-hover"to={bestProducts.items[key].readMore}>{bestProducts.items[key].title[lang]}</Link></h2>
                  <p>{bestProducts.items[key].description[lang]}</p>
                  <h3>{bestProducts.items[key].price[lang]}</h3>
                  {/* <Link className="text-more-info text-primary-hover"to={bestProducts.items[key].readMore}>{bestProducts.readMore[lang]}</Link> */}
                  <Link className="text-more-info text-primary-hover" to={bestProducts.items[key].readMore}>{bestProducts.readMore[lang]}</Link>
                </div>                
              </div>
            )}
          </div>
        </div>
      </section>
      
      {/* <!-- Section 2 --> */}
      <section className="section background-primary text-center">
        <div className="line">
          <div className="s-12 m-10 l-8 center">
          <h2 className="headline text-thin text-s-size-30">{company.moto[lang]}</h2>
          <p className="text-size-20">{company.description[lang]}</p>
          </div>
        </div>  
      </section>
      
      {/* <!-- Section 3 --> */}
      <section className="section background-white">
        <div className="full-width text-center">
          {/* <img className="center margin-bottom-30" style={{"marginTop": "-210px"}} src="img/bio.png" alt=""/> */}
          <img className="center margin-bottom-30" style={{"marginTop": "-210px"}} src={company.mainImage} alt=""/>
          <div className="line">
            <h2 className="headline text-thin text-s-size-30">{company.moto2[lang]}</h2>
            <p className="text-size-20 text-s-size-16 text-thin">{company.description2[lang]}</p>
            {/* <i className="icon-more_node_links icon2x text-primary margin-top-bottom-10"></i>
            <p className="text-size-20 text-s-size-16 text-thin text-primary">Try resize your browser window</p> */}
          </div>  
        </div>  
      </section>
      <hr className="break margin-top-bottom-0"/>
      
      {/* <!-- Section 4 -->  */}
      <section className="section background-white">
        <div className="line">
    <h2 className="text-thin headline text-center text-s-size-30 margin-bottom-50"><span className="text-primary">{newProducts.title[lang]}</span></h2>
          {/* <div className="carousel-default owl-carousel carousel-wide-arrows"> */}
          <OwlCarousel
           items={1}  
           className="carousel-default owl-carousel carousel-wide-arrows" 
           loop
           margin={8}   
           autoplay ={true}
           dots={false}
           nav
           >
            {Object.keys(newProducts.items).map((key)=>
              <div key={key} className="item">
                <div className="margin"> 
                  <div className="s-12 m-12 l-6">
                    <div className="image-border-radius margin-m-bottom">
                      <div className="margin">
                        <div className="s-12 m-12 l-4 margin-m-bottom">
                          <Link className="image-hover-zoom"to={newProducts.items[key].item1.readMore}><img src={newProducts.items[key].item1.image} alt=""/></Link>
                        </div>
                        <div className="s-12 m-12 l-8 margin-m-bottom">
                          <h3><Link className="text-dark text-primary-hover"to={newProducts.items[key].item1.readMore}>{newProducts.items[key].item1.title[lang]}</Link></h3>
                          <p>{newProducts.items[key].item1.description[lang]}</p>
                          <h3>{newProducts.items[key].item1.price[lang]}</h3>
                          <Link className="text-more-info text-primary-hover"to={newProducts.items[key].item1.readMore}>{newProducts.readMore[lang]}</Link>
                        </div>
                      </div>  
                    </div>
                  </div>
                  <div className="s-12 m-12 l-6">
                    <div className="image-border-radius">
                      <div className="margin">
                        <div className="s-12 m-12 l-4 margin-m-bottom">
                          <Link className="image-hover-zoom"to={newProducts.items[key].item2.readMore}><img src={newProducts.items[key].item2.image} alt=""/></Link>
                        </div>
                        <div className="s-12 m-12 l-8">
                          <h3><Link className="text-dark text-primary-hover"to={newProducts.items[key].item2.readMore}>{newProducts.items[key].item2.title[lang]}</Link></h3>
                          <p>{newProducts.items[key].item2.description[lang]}</p>
                          <h3>{newProducts.items[key].item2.price[lang]}</h3>
                          <Link className="text-more-info text-primary-hover"to={newProducts.items[key].item2.readMore}>{newProducts.readMore[lang]}</Link>
                        </div>
                      </div>  
                    </div>
                  </div> 
                </div>
              </div>
            )}
          </OwlCarousel>
          {/* </div> */}
        </div>    
      </section>
      
    </main>
    </>

    );
}

export default Home;