import React from 'react';
import { resources } from '../../data/resources';
import { Link } from 'react-router-dom';

function Resources(props){
  const { lang } = props;
  return(
        <>
   {/* <!-- MAIN --> */}
    <main role="main">
      {/* <!-- Content --> */}
      <article>
        <header className="section background-primary text-center">
          <h1 className="text-white margin-bottom-0 text-size-50 text-thin text-line-height-1">{resources.title[lang]}</h1>
        </header>
        <div className="section background-white"> 
          <div className="line">
            <div className="margin">
              {Object.keys(resources.items).map(key=>
                <div key={key} className="s-12 m-6 l-3">
                  <Link to={resources.items[key].readMore}>
                    <div className="image-with-hover-overlay image-hover-zoom margin-bottom">
                      <div className="image-hover-overlay background-primary"> 
                        <div className="image-hover-overlay-content text-center padding-2x">
                          <p>{resources.items[key].title[lang]}</p>  
                        </div> 
                      </div> 
                      <img src={resources.items[key].thumb} alt="" title="Portfolio Image 1" />
                    </div>
                  </Link>	
                </div>
              )}
            </div>  
          </div>
        </div> 
      </article>
    </main>

    </>

  );
}

export default Resources;