import React, { useEffect } from 'react';
// import { product } from '../../data/product';
import { products2 as product } from '../../data/products2';
import { useParams } from 'react-router-dom';

function Product(props){
  // const { itemKey, lang } = props;
  // // const params = useParams();
  // // const itemKey = params.key || 'item1_1';
  // const item = product.items[itemKey];
  //   // console.log('params', params);
  //   useEffect(() => {
  //     window.scrollTo(0, 0)
  //   }, [])
  const { lang } = props;
  const params = useParams();
  const itemKey = params.itemKey || 'product1_1';
  const item = product.items[itemKey];
    
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    return(
        <>
    {/* <!-- MAIN --> */}
    <main role="main">
      {/* <!-- Content --> */}
      <article>
        <header className="section background-primary text-center">
          <h1 className="text-white margin-bottom-0 text-size-50 text-thin text-line-height-1">{product.title[lang]}</h1>
        </header>
        <div className="section background-white"> 
          <div className="line">
            {/* <h2 className="text-size-30">{item.title[lang]}</h2>
            <p>
              <img src={item.image} alt=""/>
            </p>  */}
            {/* <blockquote className="margin-top-bottom-20">
              Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum formas humanitatis per seacula quarta decima et quinta decima.
            </blockquote> */}
            {/* <p className="margin-bottom-30">
            {item.description}
            </p> */}
            <div className="line">
              <div className="margin">
                <div className="s-12 m-12 l-4 margin-m-bottom-30">
                  {/* <h2>{item.title[lang]}</h2> */}
                  <p>
                    <img src={item.image} alt=""/>
                  </p> 
                  {/* <p>
                  {item.description[lang]}
                </p> */}
                </div>
                <div className="s-12 m-12 l-4 product-detail">
                  <h2>{item.title[lang]}</h2>
                  <p>
                    {/* <img src={item.image} alt=""/> */}
                  </p> 
                  <p>
                    <span className="product-label">{product.cateLabel[lang]}</span>{item.categoryName[lang]}
                  </p>
                  <p>
                  <span className="product-label">{product.targetLabel[lang]}</span>{item.target[lang]}
                  </p>
                  <p>
                  <span className="product-label">{product.featureLabel[lang]}</span>{item.feature[lang]}
                  </p>
                  <p>
                  <span className="product-label">{product.effectLabel[lang]}</span>{item.effect[lang]}
                  </p>
                  <p>
                  <span className="product-label">{product.amountLabel[lang]}</span>{item.amount[lang]}
                  </p>
                  <p>
                  <span className="product-label ">{product.priceLabel[lang]}</span><span className="button border-radius background-primary text-size-12 text-white text-strong">{item.price[lang]}</span>
                  </p>
                </div>
                {/* <div className="s-12 m-12 l-6 margin-m-bottom-30">
                  <h2>{item.title[lang]}</h2>
                  <p>
                    <img src={item.image} alt=""/>
                  </p> 
                  <p>
                  {item.description[lang]}
                  </p>
                </div>
                <div className="s-12 m-12 l-6">
                  <h2>{item.title[lang]}</h2>
                  <p>
                    <img src={item.image} alt=""/>
                  </p> 
                  <p>
                  {item.description[lang]}
                  </p>
                </div> */}
              </div>  
            </div>
          </div>
        </div> 
      </article>
    </main>
    </>

    );
}

export default Product;