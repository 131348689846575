// import React, { useState } from 'react';
// import { products } from '../../data/products';
// import Category from './Category';

// function Products(){
//     const [ cate, setCate ] = useState('0');
//     const items = products.items;
//     const filteredKeyArr = Object.keys(items).filter((key)=>{
//       if(cate === '0') { 
//         return true;
//       } else if(cate === items[key]['category']){
//         return true;
//       } 
//         return false;
//     });

//     return(
//         <>
//     {/* <!-- MAIN --> */}
//     <main role="main">
//       {/* <!-- Content --> */}
//       <article>
//         <header className="section background-primary text-center">
//           <h1 className="text-white margin-bottom-0 text-size-50 text-thin text-line-height-1">{products.title}</h1>
//         </header>
//           <Category cate={cate} setCate={setCate}/>
//         <div className="section background-white"> 
//           <div className="line">
//             <div className="margin text-center">
//               {filteredKeyArr.map((key) => 
//                 <div key={key} className="s-12 m-12 l-4 margin-bottom products-product">
//                   <div className="padding-2x block-bordered border-radius">
//                     <a className="image-hover-zoom" href={items[key].readMore}><img src={items[key].thumb} alt=""/></a>
//                     <h2 className="text-thin">{items[key].title}</h2>
//                     <p className="margin-bottom-30">{items[key].description}</p>
//                     <a className="button border-radius background-primary text-size-12 text-white text-strong" href={items[key].readMore}>{items[key].price}</a>
//                   </div>
//                 </div>
//               )}
//             </div>
//           </div>
          
//         </div> 
//       </article>
//     </main>

//     </>

//     );
// }

// export default Products;
import React from 'react';
import { products2 as products, category } from '../../data/products2';
import Category from './Category';
import { Link, useParams } from 'react-router-dom';

function Products(props){
    const params = useParams()
    const { lang } = props;
    const cate = params.cate || '0';
    const items = products.items;
    const filteredKeyArr = Object.keys(items).filter((key)=>{
      if(cate === '0') { 
        return true;
      } else if(cate === items[key]['category']){
        return true;
      } 
        return false;
    });

    return(
        <>
    {/* <!-- MAIN --> */}
    <main role="main">
      {/* <!-- Content --> */}
      <article>
        <header className="section background-primary text-center products-header" style={{backgroundImage: `url(${category["category"+cate].image})`}} >
            <h1 className="text-white margin-bottom-0 text-size-50 text-thin text-line-height-1" >{category["category"+cate].headTitle[lang]}</h1>
        </header>
          {/* <Category cate={cate} setCate={setCate} lang={lang}/> */}
          <Category cate={cate} lang={lang}/>
        <div className="section background-white"> 
          <div className="line">
            <div className="margin text-center">
              {filteredKeyArr.map((key) => 
                <div key={key} className="s-12 m-12 l-3 margin-bottom products-product">
                  <div className="padding-2x block-bordered border-radius">
                    {/* <a className="image-hover-zoom" href={items[key].readMore}><img src={items[key].thumb} alt=""/></a>
                    <h2 className="text-thin">{items[key].title}</h2>
                    <p className="margin-bottom-30">{items[key].description}</p>
                    <a className="button border-radius background-primary text-size-12 text-white text-strong" href={items[key].readMore}>{items[key].price}</a> */}
                    <Link className="image-hover-zoom" to={items[key].readMore}><img src={items[key].thumb} alt=""/></Link>
                    <h2 className="text-thin product-title">{items[key].title[lang]}</h2>
                    <p className="margin-bottom-30 product-description">{items[key].description[lang]}</p>
                    <Link className="button border-radius background-primary text-size-12 text-white text-strong" to={items[key].readMore}>{items[key].price[lang]}</Link>
                  </div>
                </div>
              )}
            </div>
          </div>
          
        </div> 
      </article>
    </main>

    </>

    );
}

export default Products;