import logo from './images/bioagromark.jpg';

export const site = {
    contactPhone: {ko: '전화: 031-798-1231', en:'contact us: 031-798-1231'},
    contactEmail: {ko: 'bioagro@hanmail.net', en:'bioagro@hanmail.net'},
    facebook: '/',
    twitter: '/',
    googleplus: '/',
    instagram: '/',
    logo: logo,
    logoAlt: 'bioagro-logo',
    name: {ko: '바이오아그로', en:'bioagro'}
};

export const menu = {
    Home: {ko: "홈", en:"Home"},
    Products: {ko: '상품', en:"Products"},
    About: {ko: '회사', en:"About"},
    Resources: {ko: '자료', en:"Resources"},
    Contact: {ko: '연락', en: "Contact"},
}
