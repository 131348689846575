// import React from 'react';
// import {
//   Link
// } from 'react-router-dom';

// function Header(){
//     return(
//         <>
//         {/* <!-- PREMIUM FEATURES BUTTON --> */}
//         <a target="_blank" className="hide-s" href="../template/prospera-premium-responsive-business-template/" style={{"position":"fixed","top":"120px","right":"-14px","zIndex":"10"}}><img src="img/premium-features.png" alt=""/></a>
//       {/* <!-- HEADER --> */}
//       <header role="banner">    
//         {/* <!-- Top Bar --> */}
//         <div className="top-bar background-white">
//           <div className="line">
//             <div className="s-12 m-6 l-6">
//               <div className="top-bar-contact">
//                 <p className="text-size-12">Contact Us: 0800 200 200 | <a className="text-orange-hover" href="mailto:contact@sampledomain.com">contact@sampledomain.com</a></p>
//               </div>
//             </div>
//             <div className="s-12 m-6 l-6">
//               <div className="right">
//                 <ul className="top-bar-social right">
//                   <li><a href="/"><i className="icon-facebook_circle text-orange-hover"></i></a></li>
//                   <li><a href="/"><i className="icon-twitter_circle text-orange-hover"></i></a> </li>
//                   <li><a href="/"><i className="icon-google_plus_circle text-orange-hover"></i></a></li>
//                   <li><a href="/"><i className="icon-instagram_circle text-orange-hover"></i></a></li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* <!-- Top Navigation --> */}
//         <nav className="background-white background-primary-hightlight">
//             <div className="line">
//             <div className="s-12 l-2">
//                 <a href="index.html" className="logo"><img src="img/logo-free.png" alt=""/></a>
//             </div>
//             <div className="top-nav s-12 l-10">
//                 <p className="nav-text"></p>
//                 <ul className="right chevron">
//                 <li><a href="index.html">Home</a></li>
//                 <li><a href="products.html">Products</a></li>
//                 <li><a>Services</a>
//                     <ul>
//                     <li><a>Service 1</a>
//                         <ul>
//                         <li><a>Service 1 A</a></li>
//                         <li><a>Service 1 B</a></li>
//                         </ul>
//                     </li>
//                     <li><a>Service 2</a></li>
//                     </ul>
//                 </li>
//                 <li><a href="about.html">About</a></li>
//                 <li><a href="gallery.html">Gallery</a></li>
//                 <li><a href="contact.html">Contact</a></li>
//                 </ul>
//             </div>
//             </div>
//         </nav>
//     </header>
//     </>

//     );
// }

// export default Header;
import React from 'react';
import {
  Link, 
  useLocation
} from 'react-router-dom';
import { site, menu } from '../../data/header';

function Header(props){
    const { lang, setLang } = props;
    const koreanClass = lang=== 'ko'? "button background-primary border-radius text-white languageKorean" : "button background-secondary border-radius text-white languageKorean";
    const englishClass = lang=== 'en'? "button background-primary border-radius text-white languageEnglish" : "button background-secondary border-radius text-white languageEnglish";
    
    let location = useLocation();
    const homeClass = location.pathname === "/" ? "active-item" : "";
    const productsClass = location.pathname.match(/^\/products/)  ? "active-item" : "";
    const aboutClass = location.pathname.match(/^\/about/) ? "active-item" : "";
    const resourcesClass = location.pathname.match(/^\/resources/) ? "active-item" : "";
    const contactClass = location.pathname.match(/^\/contact/) ? "active-item" : "";



    return(
        <>
        {/* <!-- PREMIUM FEATURES BUTTON --> */}
        {/* <a target="_blank" className="hide-s" href="../template/prospera-premium-responsive-business-template/" style={{"position":"fixed","top":"120px","right":"-14px","zIndex":"10"}}><img src="img/premium-features.png" alt=""/></a> */}
      {/* <!-- HEADER --> */}
      <header role="banner">    
        {/* <!-- Top Bar --> */}
        <div className="top-bar background-white">
          <div className="line">
            <div className="s-12 m-6 l-6">
              <div className="top-bar-contact">
                <p className="text-size-12">{site.contactPhone[lang]} | <a className="text-orange-hover" href={"mailto:" + site.contactEmail[lang]}>{site.contactEmail[lang]}</a></p>
              </div>
            </div>
            <div className="s-12 m-6 l-6">
              <div className="right">
                <ul className="top-bar-social right">
                  <li><a href={site.facebook}><i className="icon-facebook_circle text-orange-hover"></i></a></li>
                  <li><a href={site.twitter}><i className="icon-twitter_circle text-orange-hover"></i></a> </li>
                  <li><a href={site.googleplus}><i className="icon-google_plus_circle text-orange-hover"></i></a></li>
                  <li><a href={site.instagram}><i className="icon-instagram_circle text-orange-hover"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Top Navigation --> */}
        <nav className="background-white background-primary-hightlight">
            <div className="line">
            <div className="s-12 l-3">
              <a href="/" className="site-logo"><img src={site.logo} alt={site.logoAlt} /><span className="site-name">{site.name[lang]}</span></a>
            </div>
            <div className="s-12 l-2">
              <button type="button" className={koreanClass} onClick={()=>setLang('ko')}>한국어</button>
              <button type="button" className={englishClass} onClick={()=>setLang('en')}>English</button>
            </div>
            <div className="top-nav s-12 l-7">
                <p className="nav-text"></p>
                <ul className="right chevron">
                  {/* <li className={homeClass}><a href="/">{menu.Home[lang]}</a></li> */}
                  <li className={homeClass}><Link to="/">{menu.Home[lang]}</Link></li>
                  <li className={productsClass}><Link to="/products">{menu.Products[lang]}</Link></li>
                  <li className={aboutClass}><Link to="/about">{menu.About[lang]}</Link></li>
                  <li className={resourcesClass}><Link to="/resources">{menu.Resources[lang]}</Link></li>
                  <li className={contactClass}><Link to="/contact">{menu.Contact[lang]}</Link></li>
                </ul>
            </div>
            </div>
        </nav>
    </header>
    </>

    );
}

export default Header;