import React from 'react';
import { site } from '../../data/header';
import { philosophy, about, contact, message, copyright, design } from '../../data/footer';

function Footer(props){
  const { lang } = props;
  return(
    <>
    {/* <!-- FOOTER --> */}
    <footer>
      {/* <!-- Social --> */}
      <div className="background-primary padding text-center">
        <a href={site.facebook}><i className="icon-facebook_circle icon2x text-white"></i></a> 
        <a href={site.tweeter}><i className="icon-twitter_circle icon2x text-white"></i></a>
        <a href={site.googleplus}><i className="icon-google_plus_circle icon2x text-white"></i></a>
        <a href={site.instagram}><i className="icon-instagram_circle icon2x text-white"></i></a>                                                                        
      </div>

      {/* <!-- Main Footer --> */}
      <section className="section background-dark">
        <div className="line">
          <div className="margin">
            {/* <!-- Collumn 1 --> */}
            <div className="s-12 m-12 l-4 margin-m-bottom-2x">
              <h4 className="text-uppercase text-strong">{philosophy.title[lang]}</h4>
              <p className="text-size-20"><em>{philosophy.description[lang]}</em></p>
                            
              <div className="line">
                <h4 className="text-uppercase text-strong margin-top-30">{about.title[lang]}</h4>
                <div className="margin">
                  {/* <div className="s-12 m-12 l-4 margin-m-bottom">
                    <a className="image-hover-zoom" href="/"><img src={site.logo} alt=""/></a>
                  </div> */}
                  <div className="s-12 m-12 l-12 margin-m-bottom">
                    <p>{about.description[lang]}</p>
                    <p>{about.description1[lang]}</p>
                    <p>{about.description2[lang]}</p>
                    <a className="text-more-info text-primary-hover" href={about.readMore.url}>{about.readMore.title[lang]}</a>
                  </div>
                </div>
              </div>
            </div>
            
            {/* <!-- Collumn 2 --> */}
            <div className="s-12 m-12 l-4 margin-m-bottom-2x">
              <h4 className="text-uppercase text-strong">{contact.title[lang]}</h4>
              <div className="line">
                <div className="s-1 m-1 l-1 text-center">
                  <i className="icon-placepin text-primary text-size-12"></i>
                </div>
                <div className="s-11 m-11 l-11 margin-bottom-10">
                  <p><b>{contact.addressTitle[lang]} </b>{contact.address[lang]}</p>
                </div>
              </div>
              <div className="line">
                <div className="s-1 m-1 l-1 text-center">
                  <i className="icon-mail text-primary text-size-12"></i>
                </div>
                <div className="s-11 m-11 l-11 margin-bottom-10">
                  <p><a href="/" className="text-primary-hover"><b>{contact.emailTitle[lang]} </b>{contact.email[lang]}</a></p>
                </div>
              </div>
              <div className="line">
                <div className="s-1 m-1 l-1 text-center">
                  <i className="icon-smartphone text-primary text-size-12"></i>
                </div>
                <div className="s-11 m-11 l-11 margin-bottom-10">
                  <p><b>{contact.phoneTitle[lang]} </b>{contact.phone[lang]} </p>
                </div>
              </div>
              <div className="line">
                <div className="s-1 m-1 l-1 text-center">
                  <i className="icon-smartphone text-primary text-size-12"></i>
                </div>
                <div className="s-11 m-11 l-11 margin-bottom-10">
                  <p><b>{contact.faxTitle[lang]} </b>{contact.fax[lang]} </p>
                </div>
              </div>
              <div className="line">
                <div className="s-1 m-1 l-1 text-center">
                  <i className="icon-twitter text-primary text-size-12"></i>
                </div>
                <div className="s-11 m-11 l-11 margin-bottom-10">
                  <p><a href={contact.twitter} className="text-primary-hover"><b>{contact.twitterTitle[lang]}</b></a></p>
                </div>
              </div>
              <div className="line">
                <div className="s-1 m-1 l-1 text-center">
                  <i className="icon-facebook text-primary text-size-12"></i>
                </div>
                <div className="s-11 m-11 l-11">
                  <p><a href={contact.facebook} className="text-primary-hover"><b>{contact.facebookTitle[lang]}</b></a></p>
                </div>
              </div>
            </div>
            
            {/* <!-- Collumn 3 --> */}
            <div className="s-12 m-12 l-4">
              <h4 className="text-uppercase text-strong">{message.title[lang]}</h4>
              <form className="customform text-white">
                <div className="line">
                  <div className="margin">
                    <div className="s-12 m-12 l-6">
                      <input name="email" className="required email border-radius" placeholder={message.emailPlaceHolder[lang]} title="Your e-mail" type="text" />
                    </div>
                    <div className="s-12 m-12 l-6">
                      <input name="name" className="name border-radius" placeholder={message.namePlaceHolder[lang]} title="Your name" type="text" />
                    </div>
                  </div>
                </div>
                <div className="s-12">
                  <textarea name="message" className="required message border-radius" placeholder={message.messagePlaceHolder[lang]} rows="3"></textarea>
                </div>
                <div className="s-12"><button className="submit-form button background-primary border-radius text-white" type="submit">{message.buttonTitle[lang]}</button></div> 
              </form>
            </div>
          </div>
        </div>
      </section>
      <hr className="break margin-top-bottom-0" style={{"borderColor": "rgba(0, 38, 51, 0.80)"}}/>
      
      {/* <!-- Bottom Footer --> */}
      <section className="padding background-dark">
        <div className="line">
          <div className="s-12 l-6">
            <p className="text-size-12">{copyright.title[lang]}</p>
            <p className="text-size-12">{copyright.description[lang]}</p>
          </div>
          <div className="s-12 l-6">
            <a className="right text-size-12" href={design.url} title="Responsee - lightweight responsive framework">{design.title[lang]}<br/>{design.description[lang]}</a>
          </div>
        </div>
      </section>
    </footer> 
    </>
    );
}

export default Footer;