// import slide1 from './images/01-boxed.jpg';
import slide2 from './images/appletree1100.jpg';
import slide3 from './images/rice1100a.jpg';
import item1 from './images/parapa500cc.jpg';
import item2 from './images/pangidduk.jpg';
import item3 from './images/dduk.jpg';
import item4 from './images/cheongdduk.jpg';
import mainImage from './images/fruits.png';
import newItem1 from './images/nimoil.jpg';
import newItem2 from './images/garuliquid.jpg';
import newItem3 from './images/graolta.jpg';
import newItem4 from './images/hangeorm.jpg';

export const slides = {
    // slide1: {
    //     image: slide1,
    //     title: "Prospera is the New Amazing HTML5 Template",
    //     description: "Duis autem vel eum iriure dolor in hendrerit in vulputate velit<br/> esse molestie consequat, vel illum dolore eu feugiat nulla facilisis"
    // },
    slide2: {
        image: slide2,
        title: {ko: "깨끗한 삶", en:"Clean Life"},
        description: {ko: "바이오아그로는 깨끗한 삶을 추구합니다", en:"Bioagro pursue Clean Life"}
    },
    slide3: {
        image: slide3,
        title: {ko: "건강한 삶", en:"Healthy Life"},
        description: {ko: "바이오아그로는 건강한 삶을 추구합니다", en:"Bioagro pursue Healthy Life"}
    },
}

export const bestProducts = {
    title: {ko: "최고 상품", en:"Best Products"},
    items:{
        item1: {
            image: item1,
            title: {ko: "파라파 (500ml)", en:"Parapa (500ml)"},
            description: {ko: "천연식물 살충제", en:"Organic Pesticide"},
            price: {ko: "10,000원", en:"10,000 won"},
            readMore: "/products/1/product1_3",
        },
        item2: {
            image: item2,
            title: {ko: "팽이뚝", en:"Pangidduk"},
            description: {ko: "천연식물 살충제", en:"Organic Pesticide"},
            price: {ko: "20,000원", en:"20,000 won"},
            readMore: "/products/1/product1_4",
        },
        item3: {
            image: item3,
            title: {ko: "뚝", en:"Dduk"},
            description: {ko: "천연식물 살충제", en:"Organic Pesticide"},
            price: {ko: "20,000원", en:"20,000 won"},
            readMore: "/products/1/product1_5",
        },
        item4: {
            image: item4,
            title: {ko: "청뚝", en:"Cheongdduk"},
            description: {ko: "천연식물 살충제", en:"Organic Pesticide"},
            price: {ko: "20,000원", en:"20,000 won"},
            readMore: "/products/1/product1_6",
        },
    },
    readMore: {ko: "더보기", en:"Read more"},
}

export const company = {
    moto: {ko: "깨끗한 삶", en:"Clean Life"},
    description: {ko: "바이오아그로는 깨끗한 삶을 추구합니다", en:"Bioagro pursue Clean Life"},
    moto2: {ko: "건강한 삶", en:"Healthy Life"},
    description2: {ko: "바이오아그로는 건강한 삶을 추구합니다", en:"Bioagro pursue Healthy Life"},
    mainImage,   
}

export const newProducts = {
    title: {ko: "새 상품", en:"New Products"},
    items:{
        pair1:{
            item1: {
                image: newItem1,
                title: {ko: "님씨앗오일", en:"Neem oil"},
                description: {ko: "천연식물 살충제", en:"Organic Pesticide"},
                price: {ko: "20,000원", en:"20,000 won"},
                readMore: "/products/6/product6_9",
            },
            item2: {
                image: newItem2,
                title: {ko: "가루싹 액제", en:"Garu liquid"},
                description: {ko: "천연식물 살균제", en:"Organic Germicide"},
                price: {ko: "20,000원", en:"20,000 won"},
                readMore: "/products/2/product2_4",
            },
        },
        pair2:{
            item1: {
                image: newItem3,
                title: {ko: "그래올타", en:"Graolta"},
                description: {ko: "천연식물 살균제", en:"Organic Germicide"},
                price: {ko: "35,000원", en:"35,000 won"},
                readMore: "/products/2/product2_1",
            },
            item2: {
                image: newItem4,
                title: {ko: "한거름634", en:"Hangeorm634"},
                description: {ko: "토양 관리자재", en:"Soil Management"},
                price: {ko: "12,000원", en:"12,000 won"},
                readMore: "/products/3/product3_5",
            },
        },
    },
    readMore: {ko: "더보기", en:"Read more"}, 
}