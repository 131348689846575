import resource1 from './images-resources/1-total.jpg';
import resource1thumb from './images-resources/1.jpg';
import resource2 from './images-resources/2-byeojaebae.jpg';
import resource2thumb from './images-resources/2.jpg';
import resource3 from './images-resources/3-vegifruit.jpg';
import resource3thumb from './images-resources/3.jpg';
import resource4 from './images-resources/4-vegitable.jpg';
import resource4thumb from './images-resources/4.jpg';
import resource5 from './images-resources/5-fruits.jpg';
import resource5thumb from './images-resources/5.jpg';
import resource6 from './images-resources/6-toyang.jpg';
import resource6thumb from './images-resources/6.jpg';
import resource7 from './images-resources/7-jajae.jpg';
import resource7thumb from './images-resources/7.jpg';

export const resources = {
    title: {ko: "자료", en: "Resources"},
    items:{
        resource1: {
            thumb: resource1thumb,
            image: resource1,
            title: {ko: "제품 안내서 표지", en: "Product brochure cover"},
            description: {ko: "제품 안내서 표지", en: "Product brochure cover"},
            readMore: "/resources/resource1",
        },
        resource2: {
            thumb: resource2thumb,
            image: resource2,
            title: {ko: "친환경 벼 재배력", en: "Eco-friendly rice cultivation"},
            description: {ko: "친환경 벼 재배력", en: "Eco-friendly rice cultivation"},
            readMore: "/resources/resource2",
        },
        resource3: {
            thumb: resource3thumb,
            image: resource3,
            title: {ko: "친환경 시설채소 재배력(과채류)", en: "Eco-friendly facility vegetable cultivation"},
            description: {ko: "친환경 시설채소 재배력(과채류)", en: "Eco-friendly facility vegetable cultivation ability (fruit and vegetable)"},
            readMore: "/resources/resource3",
        },
        resource4: {
            thumb: resource4thumb,
            image: resource4,
            title: {ko: "친환경 시설채소 재배력(엽채류)", en: "Eco-friendly facility vegetable cultivation"},
            description: {ko: "친환경 시설채소 재배력(엽채류)", en: "Eco-friendly facility vegetable cultivation ability (leaf vegetable)"},
            readMore: "/resources/resource4",
        },
        resource5: {
            thumb: resource5thumb,
            image: resource5,
            title: {ko: "친환경 과수 재배력", en: "Eco-friendly fruit growing"},
            description: {ko: "친환경 과수 재배력", en: "Eco-friendly fruit growing ability"},
            readMore: "/resources/resource5",
        },
        resource6: {
            thumb: resource6thumb,
            image: resource6,
            title: {ko: "친환경 농업에 이용되는 유기비료, 토양개량제", en: "Organic fertilizer and soil improver"},
            description: {ko: "친환경 농업에 이용되는 유기비료, 토양개량제", en: "Organic fertilizer and soil improver used for eco-friendly agriculture"},
            readMore: "/resources/resource6",
        },
        resource7: {
            thumb: resource7thumb,
            image: resource7,
            title: {ko: "친환경 농업에 이용되는 병해충 방제용 자재", en: "Materials for controlling pests"},
            description: {ko: "친환경 농업에 이용되는 병해충 방제용 자재", en: "Materials for controlling pests used in eco-friendly agriculture"},
            readMore: "/resources/resource7",
        },
    },
    readMore: {ko: "더보기", en: "Read more"},
}