import React from 'react';
import { about } from '../../data/about';

function About(props){
  const { lang } = props;
    return(
        <>
    {/* <!-- MAIN --> */}
    <main role="main">
      {/* <!-- Content --> */}
      <article>
        <header className="section background-primary text-center">
          <h1 className="text-white margin-bottom-0 text-size-50 text-thin text-line-height-1">{about.title[lang]}</h1>
        </header>
        <div className="section background-white"> 
          <div className="line">
            <h2 className="text-size-30">{about.items['factory'].title[lang]}</h2>
            <p>
              <img src={about.items['factory'].image} alt=""/>
            </p> 
            {/* <blockquote className="margin-top-bottom-20">
              Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum formas humanitatis per seacula quarta decima et quinta decima.
            </blockquote> */}
            <p className="margin-bottom-30">
            {about.items['factory'].description[lang]}
            </p>
            <div className="line">
              <div className="margin">
                <div className="s-12 m-12 l-6 margin-m-bottom-30">
                  <h2>{about.items['fermentor'].title[lang]}</h2>
                  <p>
                    <img src={about.items['fermentor'].image} alt=""/>
                  </p> 
                  <p>
                  {about.items['fermentor'].description[lang]}
                  </p>
                </div>
                <div className="s-12 m-12 l-6">
                  <h2>{about.items['lab'].title[lang]}</h2>
                  <p>
                    <img src={about.items['lab'].image} alt=""/>
                  </p> 
                  <p>
                  {about.items['lab'].description[lang]}
                  </p>
                </div>
                <div className="s-12 m-12 l-6 margin-m-bottom-30">
                  <h2>{about.items['coporation'].title[lang]}</h2>
                  <p>
                    <img src={about.items['coporation'].image} alt=""/>
                  </p> 
                  <p>
                  {about.items['coporation'].description[lang]}
                  </p>
                </div>
                <div className="s-12 m-12 l-6">
                  <h2>{about.items['history'].title[lang]}</h2>
                  <p>
                    <img src={about.items['history'].image} alt=""/>
                  </p> 
                  <p>
                  {about.items['history'].description[lang]}
                  </p>
                </div>
              </div>  
            </div>
          </div>
        </div> 
      </article>
    </main>
    </>

    );
}

export default About;