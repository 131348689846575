import factory from './images-about/factory.jpg';
import fermentor from './images-about/fermentor.jpg';
import lab from './images-about/lab.jpg';
import coporation from './images-about/coporation.jpg';
import history from './images-about/history.jpg';

export const about = {
    title: "Company",
    items:{
        factory: {
            image: factory,
            title: {ko: "공장", en:"Factory"},
            description: {ko: "바이오아그로 공장입니다", en:"This is Bioagro Factory"},
            readMore: "/about/Factory",
        },
        fermentor: {
            image: fermentor,
            title: {ko: "발효기", en:"Fermentor"},
            description: {ko: "발효기입니다", en:"This is Bioagro Fermentor"},
            readMore: "/about/Fermentor",
        },
        lab: {
            image: lab,
            title: {ko: "연구실", en:"Laboratory"},
            description: {ko: "바이오아그로 연구실입니다", en:"This is Bioagro Laboratory"},
            readMore: "/about/Laboratory",
        },
        coporation: {
            image: coporation,
            title: {ko: "협력회사", en:"coporation"},
            description: {ko: "", en:""},
            readMore: "/about/coporation",
        },
        history: {
            image: history,
            title: {ko: "회사연혁", en:"company history"},
            description: {ko: "", en:""},
            readMore: "/about/history",
        },

    },
    readMore: {ko: "더보기", en:"Read more"},
}