export const philosophy= {
    title: {ko: "회사 철학", en:"OUR PHILOSOPHY"},
    description: {ko: "바이오 아그로는 깨끗하고 건강한 삶을 추구합니다", en:"Bioagro is pursuing a clean and healthy life."},
};
export const about= {
    title: {ko: "회사 소개", en:"ABOUT OUR COMPANY"},
    description: {ko: "공정거래 위원회에서 인증한 표준약관을 사용합니다. ", en:"Use standard terms and conditions certified by the Fair Trade Commission."},
    description1: {ko: "통신판매업신고 제 광주1135 호,정보 보호 담당자 : 이제승,", en:"Mail order No. 1135 in Gwangju, Information protection officer: Jaeseung Lee"},
    description2: {ko: "사업자등록번호 : 129-24-17507 대표자 박동섭 ", en:"Business registration number: 129-24-17507, Representative Dongseob Park"},
    readMore: {
        title: {ko: "더보기", en:"Read more"},
        url: "/about"
    }       
};
export const contact= {
    title: {ko: "연락", en:"Contact Us"},
    addressTitle: {ko: "주소: ", en:"Adress:"},       
    address: {ko: "경기도 광주시 초월읍 신월리 421-2 [464-864 ]", en:"421-2 Sinwol-ri, Chowol-myeon, Gwangju-si, Gyeonggi-do, South Korea"},       
    emailTitle: {ko: "이메일: ", en:"E-mail: "},       
    email: {ko: "bioagro@hanmail.net", en:"bioagro@hanmail.net"},       
    phoneTitle: {ko: "전화: ", en:"Phone: "},
    phone: {ko: "031-798-1231", en:"031-798-1231"},
    faxTitle: {ko: "팩스: ", en:"Fax: "},
    fax: {ko: "031-798-2296", en:"031-798-2296"},
    twitterTitle: {ko: "트위터", en:"Twitter"},
    twitter: "/",
    facebookTitle: {ko: "페이스북", en:"Facebook"},       
    facebook: "/",       
};
export const message= {
    title: {ko: "의견보내기", en:"LEAVE A MESSAGE"},
    emailPlaceHolder: {ko: "이메일", en:"Your e-mail"},
    namePlaceHolder: {ko: "이름", en:"Your name"},
    messagePlaceHolder: {ko: "의견", en:"Your message"},
    buttonTitle: {ko: "보내기(준비중)", en:"Submit Button ( construction )"},
};
export const copyright= {
    title: {ko: "저작권 2019, 비전디자인 - 그래픽 주", en:"Copyright 2019, Vision Design - graphic zoo"},
    // description: {ko: "", en:"All images have been purchased from Bigstock. Do not use the images in your website."},
    description: {ko: "이미지는 바이오아그로 소유임.", en:"All images are Bioagro's."},
};
export const design= {
    title: {ko: "디자인과 코딩", en:"Design and coding"},
    description: {ko: "by Responsee Team and HSPark", en:"by Responsee Team and HSPark"},
    url: "http://www.myresponsee.com",
};

